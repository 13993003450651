import { getVertexCount, enumMeshTriangles, enumMeshVertices } from './VertexEnumerator';
import { LmvVector3 } from './LmvVector3';
import { LmvBox3 } from './LmvBox3';

function remapVertices(geom, boundingBox) {
  //de-duplicate vertices based on position only (ignoring normals)

  var remap = [];
  var uniqueV = {};

  var boxScale = 1.0;
  if (geom.boundingBox || boundingBox) {
    var bbox = new LmvBox3().copy(geom.boundingBox || boundingBox);
    var sz = bbox.size();
    boxScale = Math.max(sz.x, Math.max(sz.y, sz.z));
  }

  var SCALE = (1 << 16) / boxScale; //snap scale, assuming unit mesh

  function getVertexIndex(v, i) {
    var x = 0 | v.x * SCALE;
    var y = 0 | v.y * SCALE;
    var z = 0 | v.z * SCALE;

    var mx = uniqueV[x];
    if (!mx) {
      uniqueV[x] = mx = {};
    }

    var my = mx[y];
    if (!my) {
      mx[y] = my = {};
    }

    var mz = my[z];
    if (mz === undefined) {
      my[z] = mz = i;
    }

    return mz;
  }

  function remapcb(v, i) {
    var vidx = getVertexIndex(v, i);
    remap[i] = vidx;
  }

  enumMeshVertices(geom, remapcb);

  return remap;
}


function transformVertices(geom, toWorld) {

  var vbuf = new Float32Array(3 * getVertexCount(geom));

  function cb(v, i) {
    vbuf[3 * i] = v.x;
    vbuf[3 * i + 1] = v.y;
    vbuf[3 * i + 2] = v.z;
  }

  enumMeshVertices(geom, cb, toWorld);

  return vbuf;
}

export function createWireframe(geom, toWorld, boundingBox, wantAllTriangleEdges) {

  if (geom.isLines)
  return;

  if (geom.iblines)
  return;

  //find unique vertices
  var remap = remapVertices(geom, boundingBox);

  //get vertices in world space -- we need this for
  //correct angle calculations
  var worldVerts = transformVertices(geom, toWorld);

  //loop over all triangles, keeping track of
  //edges that seem important
  var seenEdges = {};

  var edgeIB = [];

  var _v1 = new LmvVector3();
  var _v2 = new LmvVector3();
  var _v3 = new LmvVector3();
  var _n1 = new LmvVector3();
  var _n2 = new LmvVector3();

  function getV(i, v) {
    v.x = worldVerts[3 * i];
    v.y = worldVerts[3 * i + 1];
    v.z = worldVerts[3 * i + 2];
  }

  function getNormal(i1, i2, i3, n) {
    getV(i1, _v1);
    getV(i2, _v2);
    getV(i3, _v3);

    _v2.sub(_v1);
    _v3.sub(_v1);
    _v2.cross(_v3);

    n.copy(_v2).normalize();
  }

  function doOneEdge(i1orig, i2orig, opp1orig) {

    var i1 = remap[i1orig];
    var i2 = remap[i2orig];
    var opp1 = remap[opp1orig];

    //Ignore degenerates
    if (i1 === i2 || i1 === opp1 || i2 === opp1)
    return;

    var reversed = false;
    if (i1 > i2) {
      var tmp = i1;
      i1 = i2;
      i2 = tmp;
      reversed = true;
    }

    var e1 = seenEdges[i1];
    if (e1) {
      var opp2orig = e1[i2];
      if (opp2orig === undefined) {
        e1[i2] = reversed ? -opp1orig - 1 : opp1orig;
      } else {
        //We now know two triangles that share this edge,
        //we can check if it's important

        if (!wantAllTriangleEdges) {
          //Use original indices, so that we
          //can do the math with the correct winding order
          getNormal(i1orig, i2orig, opp1orig, _n1);

          if (opp2orig < 0) {
            getNormal(i2, i1, remap[-opp2orig - 1], _n2);
          } else {
            getNormal(i1, i2, remap[opp2orig], _n2);
          }

          var dot = _n1.dot(_n2);

          if (Math.abs(dot) < 0.25) {
            edgeIB.push(i1orig);
            edgeIB.push(i2orig);
          }
        } else {
          edgeIB.push(i1orig);
          edgeIB.push(i2orig);
        }

        delete e1[i2];
      }
    } else {
      seenEdges[i1] = {};
      seenEdges[i1][i2] = opp1orig;
    }
  }

  function tricb(vA, vB, vC, iA, iB, iC) {
    doOneEdge(iA, iB, iC);
    doOneEdge(iB, iC, iA);
    doOneEdge(iC, iA, iB);
  }

  //find edges that have neighboring triangles at sharp angle
  enumMeshTriangles(geom, tricb);

  //process remaining edges (outer edges that only have one triangle)

  for (var i1 in seenEdges) {
    for (var i2 in seenEdges[i1]) {
      edgeIB.push(parseInt(i1));
      edgeIB.push(parseInt(i2));
    }
  }


  if (edgeIB.length > 1) {
    geom.iblines = new Uint16Array(edgeIB.length);
    geom.iblines.set(edgeIB);
  }

  /*
        for (var i=0; i<geom.ib.length; i++) {
            geom.ib[i] = remap[geom.ib[i]];
        }
        */
}