import * as globals from '../globals';
import { GeometryList } from './GeometryList';
import { FragmentList } from './FragmentList';
import { RenderBatch } from './RenderBatch';
import { consolidateFragmentList } from './FragmentListConsolidation';
import { ConsolidationIterator } from './ConsolidationIterator';
import { ModelIteratorLinear } from './ModelIteratorLinear';
import { ModelIteratorBVH } from './ModelIteratorBVH';
import { ModelIteratorTexQuad } from './ModelIteratorTexQuad';
import { VBIntersector } from './VBIntersector';
import * as THREE from "three";
import { MeshFlags } from "./MeshFlags";
import { RenderFlags } from "./RenderFlags";
import { logger } from "../../logger/Logger";

// TODO: move the logic that decides whether or not to stream somewhere closer to SVF;
// Ideally, RenderModel and GeometryList should be agnostic to the file format.
/*
 * Helper function to determine whether we should enable streamingDraw or upload the whole model to GPU.
 *
 * This function uses values from an SVF package to estimate the expected GPU load. If it is
 * small enough, it returns false. This means that the whole model is uploaded to GPU.
 *
 * If the model size is larger or unknown, we use a heuristic to determine which models are uploaded
 * to GPU and which are rendered from CPU-memory using the (slower) streamingDraw.
 *  @param {number} packFileTotalSize
 *  @param {number} numPrimitives
 *  @param {number} numObjects
 */
function needsStreamingDraw(packFileTotalSize, numPrimitives, numObjects) {
  if (packFileTotalSize) {
    //In pack files, primitive indices use 4 byte integers,
    //while we use 2 byte integers for rendering, so make this
    //correction when estimating GPU usage for geometry
    var estimatedGPUMem = packFileTotalSize - numPrimitives * 3 * 2;

    //If the model is certain to be below a certain size,
    //we will skip the heuristics that upload some meshes to
    //GPU and keep other in system mem, and just push it all to the GPU.
    if (estimatedGPUMem <= globals.GPU_MEMORY_LIMIT && numObjects < globals.GPU_OBJECT_LIMIT) {
      // We don't need streaming draw - model is small enough
      return false;
    }
  }

  return true;
}

// Counter to assign individual numbers to RenderModel in order of their creation
var nextModelId = 1;

/** @class Extends application Model class by functionality for WebGL rendering.
                      *         Currently produced by loaders (F2DLoader, SvfLoader)
                      *
                      *  @constructor
                      */
export function RenderModel() {

  // Cached bboxes.
  var _visibleBounds = new THREE.Box3(); // excluding ghosted once
  var _visibleBoundsWithHidden = new THREE.Box3(); // full bbox
  var _tmpBox = new THREE.Box3(); // temp for internal use

  this.visibleBoundsDirty = false; // triggers recomputation of _visibleBounds and _visibleBoundsWithHidden, e.g., if fragment visibility changes.
  this.enforceBvh = false; // currently ignored, see this.resetIterator()

  var _numHighlighted = 0; // number of currently highlighted fragments.    

  this.id = nextModelId++; // use next free Model id

  var _geoms = null; // {GeometryList} 
  var _frags = null; // {FragmentList}

  // Iterators used for scene traversal. 
  var _linearIterator = null; // {ModelIteratorLinear}, used by default and created in this.initialize()
  var _bvhIterator = null; // {ModelIteratorBVH},    used if setBVH() has been called and no new fragments have been added since then.
  var _iterator = null; // currently used iterator. points to one of the iterators above

  // Only used for consolidated models.
  var _consolidationIterator = null; // {ConsolidationIterator}
  var _consolidationMap = null; // cached intermediate results of consolidation pre-processing. Enables to quickly rebuild
  // _consolidationIterator when we had to temporarily remove it to free memory.

  // Maintained per scene traversal, initialized in ResetIterator()
  var _renderCounter = 0; // number of batches rendered since last resetIterator() call. Used to indicate rendering progress for progressive rendering.
  var _frustum = null; // {FrustumIntersector}. Assigned in this.ResetIterator(). Passed to RenderBatches for culling and depth-sorting. 
  var _drawMode = RenderFlags.RENDER_NORMAL; // drawMode used in this traversal. See Viewer3DImpl.js
  var _bvhOn = false; // true when using _bvhiterator in the current traversal. [HB:] Shouldn't this better be local variable in ResetIterator()?

  // Note: GeometryList or FragmentList are maintained by the RenderModel and should not be modified from outside.
  //       E.g., setting visibility or highlighting flags on FragmentList directly would break some state tracking. (e.g. see this.setVibility or this.setHighlighted)
  //       The only current exception is done by loaders that add geometry to _geoms directly.
  this.getGeometryList = function () {return _geoms;};
  this.getFragmentList = function () {return _frags;};
  this.getModelId = function () {return this.id;};
  this.RenderBatch = RenderBatch; // This will be used by the model iterators
  // to create batches. Limited memory will change it.


  this.initialize = function () {
    // alloc GeometryList. Initially empty, but exposed via GetGeometryList().
    // The loaders use this to add LmvGeometryBuffers directly to the GeometryList later.
    // TODO: Make RenderModel agnostic to the SVF file format.
    var svf = this.getData();

    var numObjects = svf.numGeoms || 0;
    var disableStreaming = !needsStreamingDraw(svf.packFileTotalSize, svf.primitiveCount, numObjects);
    _geoms = new GeometryList(numObjects, this.is2d(), disableStreaming, this.isOTG());

    _frags = new FragmentList(this);

    var initialBbox = this.getBoundingBox();
    if (initialBbox) {
      _visibleBounds.copy(initialBbox);
      _visibleBoundsWithHidden.copy(initialBbox);
    }

    _iterator = _linearIterator = new ModelIteratorLinear(this);
  };

  this.getIterator = function () {
    return _iterator;
  };

  /**
      * Initialize from custom iterator. In this case, _geoms and _frags are not used and the 
      * iterator implementation is responsible for producing and maintaining the geometry.
      *
      *  @param {ModelIterator} iterator - iterator.nextBatch may return RenderBatch or THREE.Scene instances.
      *
      * Note: When using a custom iterator, per-fragment visiblity is not supported.
      */
  this.initFromCustomIterator = function (iterator) {
    _iterator = iterator;
    this.visibleBoundsDirty = true; // make sure that bbox is obtained from iterator
  };

  /** 
      *  Deletes all GPU resources.
      *
      *  @param {FireflyWebGLRenderer} glRenderer
      */
  this.dtor = function (glrenderer) {
    if (_frags) {
      _frags.dispose(glrenderer);
    }
    // Custom iterators may have own GPU resources (see ModelIteratorTexQuad)
    if (_iterator && _iterator.dtor) {
      _iterator.dtor();
      _linearIterator = null;
    }
    // If this model was consolidated, dispose GPU memory of consolidation as well
    if (_consolidationIterator) {
      _consolidationIterator.dispose();
    }
  };


  /** 
      * Activating a fragment means:
      *  - Store geometry in the FragmentList
      *  - Update summed RenderModel boxes
      *  - Add fragment to iterator, so that it is considered in next traversal
      * See FragmentList.setMesh(..) for param details.
      *
      * Note:
      *  - Can only be used with LinearIterator
      */
  this.activateFragment = function (fragId, meshInfo, overrideTransform) {
    if (!_frags) {
      return;
    }

    _frags.setMesh(fragId, meshInfo, overrideTransform);

    //The linear iterator can be updated to add meshes incrementally.
    //The BVH iterator is not mutable, yet.
    _iterator.addFragment(fragId);

    //update the world bbox
    {
      _frags.getWorldBounds(fragId, _tmpBox);
      _visibleBounds.union(_tmpBox);
      _visibleBoundsWithHidden.union(_tmpBox);
    }

  };

  // Used by the Fusion collaboration client
  this.setFragment = function (fragId, mesh) {

    if (fragId === undefined)
    fragId = this.getFragmentList().getNextAvailableFragmentId();

    _frags.setMesh(fragId, mesh, true);

    //The linear iterator can be updated to add meshes incrementally.
    //The BVH iterator is not mutable, yet.
    if (_linearIterator)
    _linearIterator.addFragment(fragId);
    if (_bvhIterator && !_frags.fragmentsHaveBeenAdded())
    _bvhIterator.addFragment(fragId);

    //update the world bbox
    {
      _frags.getWorldBounds(fragId, _tmpBox);
      _visibleBounds.union(_tmpBox);
      _visibleBoundsWithHidden.union(_tmpBox);
    }

    return fragId;
  };


  /** Replaces the default LinearIterator by a BVH iterator. */
  this.setBVH = function (nodes, primitives, options) {

    // Note that ResetIterator() might still set _iterator back to 
    // the linear one if the BVH one cannot be used.
    _iterator = _bvhIterator = new ModelIteratorBVH();

    _iterator.initialize(this, nodes, primitives, options);

  };

  /** 
      *  Starts the scene draw traversal, so that nextBatch() will return the first batch to render.
      *   @param: {UnifiedCamera}      camera       - camera.position was needed for the heuristic to choose between linear iterator and BVH.
      *                                               [HB:] The code is currently outcommented, so the param is currently unused.
      *   @param: {FrustumIntersector} frustum      - used by RenderBatches for frustum culling and z-sorting.
      *   @param: {number}             drawMode     - E.g., RENDER_NORMAL. See RenderFlags.js
      */
  this.resetIterator = function (camera, frustum, drawMode) {

    //Decide whether to use the BVH for traversal
    //If we are far out from the scene, use pure big-to-small
    //importance order instead of front to back.
    _bvhOn = false;
    if (_bvhIterator && !_frags.fragmentsHaveBeenAdded()) {
      //TODO: BVH always on when available, because the linear iteration
      //does not respect transparent objects drawing last -- it just
      //draws in the order the fragments come in the SVF package
      /*
          if(this.enforceBvh || !_linearIterator) {
              _bvhOn = true;
          } else {
              var diag = _visibleBoundsWithHidden.size().length();
              var center = _visibleBoundsWithHidden.center();
              var dist = camera.position.distanceTo(center);
              if (dist < diag * 0.5)
                  _bvhOn = true;
          }
          */
      _bvhOn = true;
    }

    // Note _linearIterator may also be null if a custom iterator is used.
    // in this case, we must leave _iterator unchanged.
    if (_bvhOn) {
      _iterator = _bvhIterator;
    } else if (_linearIterator) {
      _iterator = _linearIterator;
    }

    _renderCounter = 0;
    _drawMode = drawMode;
    _frustum = frustum;
    _iterator.reset(frustum, camera);

    // notify consolidation iterator that a new traversal has started
    if (_consolidationIterator) {
      _consolidationIterator.reset();
    }
    return _iterator;
  };


  /** Returns the next RenderBatch for scene rendering travseral. Used in RenderScene.renderSome().
      *   Use this.resetIterator() to start traversal first.
      *
      *   @returns {RenderBatch|null} Next batch to render or null if traversal is finished.
      */
  this.nextBatch = function () {

    // If the next batch of the iterator is fully invisble, we inc it until we 
    // find a relevant batch to render or reach the end.
    while (1) {
      // get next batch from iterator
      var scene = _iterator.nextBatch();

      // update render progress counter
      _renderCounter++;

      // stop if iterator reached the end           
      if (!scene)
      return null;

      // replace RenderBatch (= individual fragments) by consolidated scene, if available
      if (_consolidationIterator && scene instanceof RenderBatch) {
        scene = _consolidationIterator.consolidateNextBatch(scene, _frustum);
      }

      // Tag all produced scenes with modelId. This is used for cross-fading between models by
      // rendering them to separate targets.
      scene.modelId = this.id;

      if (scene instanceof THREE.Scene) {
        // The code for fragment visibility and sorting is only defined if scene is a RenderBatch.
        // For the case of THREE.Scene, we are done here, because
        //   - Sorting in THREE.Scene is done by FireFlyRenderer.
        //   - Per-fragment visiblity is not supported in this case
        return scene;
      }

      if (!this.applyVisibility(scene, _drawMode, _frustum))
      return scene;
    }
  };

  /**
      * Set the MESH_RENDERFLAG based on the current render phase
      * while frustum culling the fragments in the scene.
      * @param {RenderBatch} scene The scene to calculate the visibility for
      * @param {number}             drawMode     - E.g., RENDER_NORMAL. See RenderFlags.js
      * @param {FrustumIntersector} frustum      - used by RenderBatches for frustum culling and z-sorting.
      * @return {boolean} True if all fragments in the scene are not visibile. False otherwise.
      */
  this.applyVisibility = function (scene, drawMode, frustum) {
    //TODO: move this into the iterator?
    var allHidden = scene.applyVisibility(
    drawMode,
    frustum);

    // For 3D scenes, sort fragments of this batch. 
    // Note that fragments of F2D scenes must be drawn in original order.
    //TODO: Move this to the iterator?
    if (!allHidden && !this.is2d()) {
      //Generally opaque batches are sorted once by material, while
      //transparent batches are sorted back to front each frame
      if (scene.sortObjects && !this.getFragmentList().useThreeMesh)
      scene.sortByDepth(frustum);else
      if (!scene.sortDone)
      scene.sortByMaterial();
    }

    return allHidden;
  };

  /**
      * @param:  {bool}        includeGhosted
      * @returns {THREE.Box3} 
      *
      * NOTE: The returned box is just a pointer to a member, not a copy!
      */
  this.getVisibleBounds = function (includeGhosted) {

    if (this.visibleBoundsDirty) {

      _visibleBounds.makeEmpty();
      _visibleBoundsWithHidden.makeEmpty();

      _iterator.getVisibleBounds(_visibleBounds, _visibleBoundsWithHidden, includeGhosted);


      this.visibleBoundsDirty = false;

    }

    return includeGhosted ? _visibleBoundsWithHidden : _visibleBounds;
  };

  /**
      * Performs a raytest and returns an object providing information about the closest hit. 
      * 
      * NOTE: We currently ignore hitpoints of fragments that are visible (MESH_VISIBLE==true) and not highlighted (MESH_HIGHLIGHTED==false). 
      *
      * @param {THREE.RayCaster} raycaster
      * @param [bool]            ignoreTransparent 
      * @param {number[]}       [dbIds]             - Array of dbIds. If specified, only fragments with dbIds inside the filter are checked.
      *                                                If the model data has no instanceTree, this is just a whitelist of explicit fragment ids.
      *                                                Note that a hitpoint will also be returned if it's actually occluded by a fragment outside the filter.
      *
      * @returns {Object|null}   Intersection result object r providing information about closest hit point. Properties:
      *                           - {number}   fragId
      *                           - {Vector3}  point
      *                           - {number}   dbId
      *                           - {model}    model - pointer to this RenderModel
      *                          (created/filled in VBIntersector.js, see for details)
      */
  // Add "meshes" parameter, after we get meshes of the object using id buffer,
  // then we just need to ray intersect this object instead of all objects of the model.
  this.rayIntersect = function (raycaster, ignoreTransparent, dbIds, intersections) {

    // make sure that the cached overall bboxes are up-to-date.
    // [HB:] Why are they updated here, but not used in this method?
    if (this.visibleBoundsDirty)
    this.getVisibleBounds();

    // alloc array to collect intersection results
    var intersects = [];
    var i;

    // Restrict search to certain dbIds if specified...
    if (dbIds && dbIds.length > 0) {

      //Collect the mesh fragments for the given database ID node filter.
      var instanceTree = this.getInstanceTree();
      var fragIds = [];
      if (instanceTree) {
        for (i = 0; i < dbIds.length; i++) {
          instanceTree.enumNodeFragments(dbIds[i], function (fragId) {
            fragIds.push(fragId);
          }, true);
        }
      } else {
        //No instance tree -- treat dbIds as fragIds
        fragIds = dbIds;
      }

      //If there are multiple fragments it pays to still use
      //the bounding volume hierarchy to do the intersection,
      //because it can cull away entire fragments by bounding box,
      //instead of checking every single fragment triangle by triangle
      if (fragIds.length > 2) {//2 is just an arbitrary value, assuming checking 2 fragments is still cheap than full tree traversal
        _iterator.rayCast(raycaster, intersects, dbIds);
      } else {
        // The filter restricted the search to a very small number of fragments.
        // => Perform raytest on these fragments directly instead.
        for (i = 0; i < fragIds.length; i++) {
          var mesh = _frags.getVizmesh(fragIds[i]);
          if (!mesh)
          continue;
          var res = VBIntersector.rayCast(mesh, raycaster, intersects);
          if (res) {
            intersects.push(res);
          }
        }
      }

    } else {
      // no filter => perform raytest on all fragments
      _iterator.rayCast(raycaster, intersects);
    }

    // stop here if no hit was found
    if (!intersects.length)
    return null;

    // sort results by distance. 
    intersects.sort(function (a, b) {return a.distance - b.distance;});

    //pick the nearest object that is visible as the selected.
    var allIntersections = !!intersections;
    intersections = intersections || [];

    for (i = 0; i < intersects.length; i++) {
      var fragId = intersects[i].fragId;

      //skip past f2d consolidated meshes.
      //TODO: we should completely avoid intersecting those in the ray caster.
      if (this.is2d())
      continue;

      var isVisible = this.isFragVisible(fragId); //visible set,

      // [HB:] Since we skip all meshes that are not flagged as visible, shouldn't we 
      //       better exclude them from the raycast in the first place?
      if (isVisible) {

        // skip transparent hits if specified
        var material = _frags.getMaterial(fragId);
        if (ignoreTransparent && material.transparent)
        continue;

        var intersect = intersects[i];

        // check against cutplanes
        var isCut = false;
        var intersectPoint = intersect.point;
        if (material && material.cutplanes) {
          for (var j = 0; j < material.cutplanes.length; j++) {
            isCut = isCut || material.cutplanes[j].dot(new THREE.Vector4(
            intersectPoint.x, intersectPoint.y, intersectPoint.z, 1.0)) >
            1e-6;
          }
        }

        if (!isCut) {
          intersections.push(intersect);
        }

        intersect.model = this;

        if (!allIntersections && intersections.length > 0) {
          // result is the closest hit that passed all tests => done.
          break;
        }
      }
    }

    var result = intersections[0] || null;

    if (result) {
      // We might use multiple RenderModels => add this pointer as well.
      result.model = this;
    }

    return result;
  };


  /** Set highlighting flag for a fragment. 
      *   @param   {number} fragId
      *   @param   {bool}   value
      *   @returns {bool}   indicates if flag state changed
      */
  this.setHighlighted = function (fragId, value) {
    if (!_frags) {
      return false;
    }

    var changed = _frags.setFlagFragment(fragId, MeshFlags.MESH_HIGHLIGHTED, value);

    if (changed) {
      if (value)
      _numHighlighted++;else

      _numHighlighted--;
    }

    return changed;
  };

  /** Sets MESH_VISIBLE flag for a fragment (true=visible, false=ghosted) */
  // This function should probably not be called outside VisibityManager
  // in order to maintain node visibility state.
  this.setVisibility = function (fragId, value) {
    if (_frags) {
      _frags.setVisibility(fragId, value);
      this.visibleBoundsDirty = true;
    }
  };

  /** Sets MESH_VISIBLE flag for all fragments (true=visible, false=ghosted) */
  this.setAllVisibility = function (value) {
    if (_frags) {
      _frags.setAllVisibility(value);
      this.visibleBoundsDirty = true;
    }
  };

  /** Sets the MESH_HIDE flag for all fragments that a flagged as line geometry. 
      *  Note that the MESH_HIDE flag is independent of the MESH_VISIBLE flag (which switches between ghosted and fully visible) 
      *
      *  @param {bool} hide - value to which the MESH_HIDE flag will be set. Note that omitting this param would SHOW the lines, so
      *                       that you should always provide it to avoid confusion.
      */
  this.hideLines = function (hide) {
    if (_frags) {
      _frags.hideLines(hide);
    }
  };

  /** Sets the MESH_HIDE flag for all fragments that a flagged as point geometry. 
      *  Note that the MESH_HIDE flag is independent of the MESH_VISIBLE flag (which switches between ghosted and fully visible) 
      *
      *  @param {bool} hide - value to which the MESH_HIDE flag will be set. Note that omitting this param would SHOW the points, so
      *                       that you should always provide it to avoid confusion.
      */
  this.hidePoints = function (hide) {
    if (_frags) {
      _frags.hidePoints(hide);
    }
  };

  /** Returns if one or more fragments are highlighed. 
      *   returns {bool}
      *
      * Note: This method will only work correctly as long as all highlighting changes are done via this.setHighlighted, not on FragmentList directly.
      */
  this.hasHighlighted = function () {
    return !!_numHighlighted;
  };

  /** Returns true if a fragment is tagged as MESH_VISIBLE and not as MESH_HIGHLIGHTED. */
  // 
  // [HB:] It's seems a bit unintuitive that the MESH_HIGHLIGHTED flag is checked here, but not considered by the other visibility-related methods.
  //       For instance, consider the following scenarioes:
  //        - After calling setVibility(frag, true), isFragVisible(frag) will still return false if frag was highlighed.
  //        - If areAllVisible() returns true, there may still be fragments for which isFragVisible(frag) returns false.
  this.isFragVisible = function (frag) {
    return _frags.isFragVisible(frag);
  };

  /** Returns true if MESH_VISIBLE flag is set for all fragments. */
  this.areAllVisible = function () {

    // When using a custom iterator, we don't have per-fragment visibility control. 
    // We assume constantly true in this case.
    if (!_frags) {
      return true;
    }

    return _frags.areAllVisible();
  };

  /** Direct access to all RenderBatches. Used by ground shadows and ground reflection.
       * @returns {RenderBatch[]}
       */
  this.getGeomScenes = function () {return _iterator.getGeomScenes();};

  /** Get progress of current rendering traversal.
                                                                          *  @returns {number} in [0,1]
                                                                          */
  this.getRenderProgress = function () {
    var progress = _renderCounter / _iterator.getSceneCount();
    // the renderCounter can become > scene count.
    return progress > 1.0 ? 1.0 : progress;
  };

  /**
      *  @params  {number} timeStamp
      *  @returns {bool}   true if the model needs a redraw
      */
  this.update = function (timeStamp) {
    // if there is an iterator that implements update method...
    if (_iterator && _iterator.update) {
      return _iterator.update(timeStamp);
    }
    // assume constant scene otherwise
    return false;
  };


  /** Highlight an object with a theming color that is blended with the original object's material.
      *   @param {number}        dbId
      *   @param {THREE.Vector4} themingColor (r, g, b, intensity), all in [0,1]
      *   @param {boolean} [recursive] - Should apply theming color recursively to all child nodes.
      */
  this.setThemingColor = function (dbId, color, recursive) {

    // When using 2d with Otg db, we need to remap, because the vertex-buffers still contain otg.
    dbId = this.reverseMapDbId(dbId);

    if (_frags) {
      var it = this.getInstanceTree();
      if (recursive && it) {
        it.enumNodeChildren(dbId, function (childDbId) {
          _frags.setThemingColor(childDbId, color);
        }, true);
      } else {
        _frags.setThemingColor(dbId, color);
      }
    } else {
      logger.warn("Theming colors are not supported by this model type.");
    }
  };

  /** Revert all theming colors.
      *   @param {number}        dbId
      *   @param {THREE.Vector4} themingColor (r, g, b, intensity), all in [0,1]
      */
  this.clearThemingColors = function () {
    if (_frags) {
      _frags.clearThemingColors();
    }
  };

  /** Access to leaflet-specific functionality. Returns null if RenderModel is no leaflet. */
  this.getLeaflet = function () {
    if (_iterator instanceof ModelIteratorTexQuad) {
      return _iterator;
    }
    return null;
  };

  /**
      * This function creates an internal copy of the FragmentList that is consolidated to reduce the
      * shape count as far as possible. This takes more memory, but may strongly accelerate rendering
      * for models with many small shapes.
      *
      * NOTE: For making consolidation effective, it should ideally be activated via the load options already.
      *       This will automatically adjust the depth of the spatial hierarchy. Without that, the scene traversal
      *       may still be slow and the performance gain much smaller.
      *
      * @param {MaterialManager} materials
      * @param {number}          [byteLimit = 100 << 20] - Merging geometries is the most efficient technique in terms
      *                                                    of rendering performance. But, it can strongly increase
      *                                                    the memory consumption, particularly because merged
      *                                                    geometry cannot be shared, i.e. multiple instances of
      *                                                    a single geometry must be replicated per instance for merging.
      *                                                    Therefore, the memory spent for merging is restricted.
      *                                                    A higher value may make rendering faster, but increases (also GPU) memory
      *                                                    workload.
      * @param {boolean}         [multithreaded]         - Optional: If true, a part of the work is delegated to a worker thread.
      *                                                    This function will return faster, but the consolidation is marked as not usable
      *                                                    (see Consolidation.inProgress) until all worker results are returned.
      *
      * @param {FireFlyWebGLRenderer} glRenderer
      */
  this.consolidate = function (materials, byteLimit, glRenderer) {

    // consolidate fragment list
    var consolidation = consolidateFragmentList(this, materials, byteLimit, glRenderer, _consolidationMap);

    // make BVHIterator use the consolidation when possible
    _consolidationIterator = new ConsolidationIterator(_frags, consolidation);

    // cache some intermediate results. Consolidations are memory-intensive, so it can be necessary to temporarily
    // remove them to free memory. By caching intermediate results, we can rebuild them faster.
    _consolidationMap = consolidation.consolidationMap;
  };

  /**
      * Removes consolidation to free memory. Just some compact intermediate results are cached, so that the
         * consolidation can be rebuilt quickly.
         */
  this.unconsolidate = function () {
    if (!_consolidationIterator) {
      return;
    }

    _consolidationIterator.dispose();
    _consolidationIterator = null;
  };

  this.isConsolidated = function () {
    return !!_consolidationIterator;
  };

  this.getConsolidation = function () {
    return _consolidationIterator ? _consolidationIterator.getConsolidation() : null;
  };

  //F2D only -- mapping of F2D dbids to OTG property database v2 dbids
  this.setDbIdRemap = function (dbidOldToNew) {
    if (this.is2d) {
      this.idRemap = dbidOldToNew;
    }
  };

  //F2D only -- maps ID stored in F2D vertex buffers to actual dbId as used
  //by v2/OTG property databases.
  this.remapDbId = function (dbId) {
    if (this.idRemap && dbId > 0 && dbId < this.idRemap.length)
    return this.idRemap[dbId];

    return dbId;
  };

  this.reverseMapDbId = function (dbId) {
    if (!this.idRemap || dbId <= 0)
    return dbId;

    if (!this.reverseMap) {
      this.reverseMap = {};
      for (var i = 0; i < this.idRemap.length; i++) {
        this.reverseMap[this.idRemap[i]] = i;}
    }

    return this.reverseMap[dbId];
  };


  /**
      * This function is only needed if...
      *
      *   1. You want to draw a fragment to an overlay scene that overdraws the original fragment, and
      *   2. Consolidation is used for this model.
      *
      *  To avoid flickering artifacts, the geometry used for the overlay scene must exactly match with the
      *  one used for the main scene rendering. However, when consolidation is used, this geometry may vary
      *  and (slightly) differ from the original fragment geometry.
      *
      *  This function updates the given render proxy to make it exactly match with the geometry used for the
      *  the last main scene rendering. This involves to replace geometry, material, and matrix when necessary.
      *
      *  NOTE: An updated proxy can exclusively be used for rendering. Do not use this function if you want to
      *        access any vertex data directly.
      *
      *   @param {THREE.Mesh} proxy  - currently used proxy mesh to represent the fragment
      *   @param {Number}     fragId - fragment represented by this proxy */
  this.updateRenderProxy = function (proxy, fragId) {

    if (!_consolidationIterator) {
      // nothing to do - rendering will always use the original geometry anyway.
      return;
    }

    // fragment might be consolidated.
    _consolidationIterator.updateRenderProxy(proxy, fragId);
  };

  this.skipOpaqueShapes = function () {
    if (_iterator && _iterator.skipOpaqueShapes) {
      _iterator.skipOpaqueShapes();
    }
  };

}