
import { CONTAINS, OUTSIDE } from "../../wgs/scene/FrustumIntersector";
import { isMobileDevice, getGlobal } from "../../compat";
import { BufferGeometryUtils } from "../../wgs/scene/BufferGeometry";
import { createWorkerWithIntercept } from "./WorkerCreator";
import { initLoadContext } from "../net/endpoints";
import { EventDispatcher } from "../../application/EventDispatcher";
import * as et from "../../application/EventTypes";
import { LmvVector3 as Vector3 } from '../../wgs/scene/LmvVector3';
import { LmvBox3 as Box3 } from '../../wgs/scene/LmvBox3';
import { getParameterByName } from "../../globals";

export var MESH_RECEIVE_EVENT = "meshReceived";
export var MESH_FAILED_EVENT = "meshFailed";


// Returns the surface area of a THREE.Box3.
function getBoxSurfaceArea(box) {
  var dx = box.max.x - box.min.x;
  var dy = box.max.y - box.min.y;
  var dz = box.max.z - box.min.z;
  return 2.0 * (dx * dy + dy * dz + dz * dx);
}

var disableIndexedDb = getParameterByName("disableIndexedDb") || getGlobal().DISABLE_INDEXED_DB;
var disableWebSocket = getParameterByName("disableWebSocket") || getGlobal().DISABLE_WEBSOCKET;

function initLoadContextGeomCache(msg) {
  var ctx = initLoadContext(msg);
  ctx.disableIndexedDb = disableIndexedDb;
  ctx.disableWebSocket = disableWebSocket;
  return ctx;
}

// @param {THREE.Vector3} p
// @param {THREE.Vector3} bboxMin
// @param {THREE.Vector3} bboxMax
// @returns {Number} Squared distance of the bbox to p    
var point2BoxDistance2 = function () {

  var _nearest = null;

  return function (p, boxMin, boxMax) {
    if (!_nearest) _nearest = new Vector3();

    // compute the point within bbox that is nearest to p by clamping against box
    _nearest.copy(p);
    _nearest.max(boxMin);
    _nearest.min(boxMax);

    // return squared length of the difference vector
    return _nearest.distanceToSquared(p);
  };
}();

// Helper function used for cache cleanup
function compareGeomsByImportance(geom1, geom2) {
  return geom1.importance - geom2.importance;
}

// Sort requests by decreasing importance
function compareRequests(req1, req2) {
  return req2.importance - req1.importance;
}

/** Read fragment from Float32-Array (storing each box as 6 floats)    
   *  @param {Float32Array} boxes 
   *  @param {number}       index 
   *  @param {THREE.Box3}   outBox
   *  @returns {THREE.Box3} outBox
   */
function readFragmentBox(boxes, index, outBox) {
  var offset = 6 * index;
  outBox.min.y = boxes[offset + 1];
  outBox.min.z = boxes[offset + 2];
  outBox.min.x = boxes[offset + 0];
  outBox.max.x = boxes[offset + 3];
  outBox.max.y = boxes[offset + 4];
  outBox.max.z = boxes[offset + 5];
  return outBox;
}

/**
   * @param {number}            fragId
   * @param {Float32Array}      boxes
   * @param {FrustumInersector} frustum 
   */
var computeFragImportance = function () {

  var _tmpBox = null;

  return function (fragId, boxes, frustum) {

    if (!_tmpBox) _tmpBox = new Box3();

    // get fragment box
    var fragBox = readFragmentBox(boxes, fragId, _tmpBox);

    // frustum test
    var cullResult = frustum.intersectsBox(fragBox);

    // outside frustum => no importance
    if (cullResult === OUTSIDE) {
      return 0.0;
    }

    // Estimate projected area. For shapes fully inside the frustum, we can
    // skip the clipping step. 
    var noClip = cullResult === CONTAINS;
    var area = frustum.projectedBoxArea(fragBox, noClip);

    var dist = point2BoxDistance2(frustum.eye, fragBox.min, fragBox.max);
    dist = Math.max(dist, 0.01);

    return area / dist;
  };
}();

/** Shared cache of BufferGeometries used by different OtgLoaders.
      *   @param {Viewer3D} viewer - parent viewer. Needed to track which models are in use (see cache cleanup)
      */
export function OtgGeomCache(viewer) {

  // all geometries, indexed by geom hashes
  var _geoms = new Map();

  // A single geometry may be requested by one or more model loaders.
  // This map keeps track of requests already in progress so that
  // we don't issue multiple simultaneously
  var _geomHash2Requests = {};

  // worker for geometry loading
  var NUM_WORKERS = isMobileDevice() ? 2 : 8;
  var _workers = [];
  var _ranges;

  for (var i = 0; i < NUM_WORKERS; i++) {
    _workers.push(createWorkerWithIntercept());
  }

  // track memory consumption
  this.byteSize = 0;

  // A request is called in-progress if we have sent it to the worker and didn't receive a result yet.
  // We restrict the number of _requestsInProgress. If the limit is reached, all additional requests
  // are enqueued in _waitingRequests.
  var _requestsInProgress = 0;
  var _maxRequestsPerWorker = 100;
  var _timeout = undefined;

  // If the number of requests in progress exceeds _maxRequests, all remaining ones are enqueued in this array.
  // Requests outside the worker can be rearranged based on priority changes (if model visibility changes).
  var _waitingTasks = []; // enqueued task messages to OTGGeomWorker, as defined in requestGeometry(...)

  // Optional: Specifies hashes that should be loaded with maximum priority. (e.g., if quickly needed for a computation)
  var _urgentHashes = {};

  var _prevNumTasks = 0;
  var _fullSortDone = false;

  var _this = this;

  // mem limits for cache cleanup
  var MB = 1024 * 1024;
  var _maxMemory = 100 * MB; // geometry limit at which cleanup is activated
  var _minCleanup = 50 * MB; // minimum amount of freed memory for a single cleanup run

  var _timeStamp = 0; // used for cache-cleanup to identify which geoms are in use

  // Needed for cache-cleanup to check which RenderModels are loaded
  var _viewer = viewer;

  // A cleanup will fail if there are no unused geometries anymore.
  // If this happens, we skip cleanup until the next model unload occurs.
  var _allGeomsInUse = false;

  // Whenever the camera or set of visible models change, we have to update request priorities.
  // These members are used to track relevant changes.
  var _lastCamPos = new Vector3();
  var _lastCamTarget = new Vector3();
  var _lastVisibleModelIds = []; // {number[]} ids of all visible RenderModels that we considered for last update

  function onModelUnloaded() {
    _allGeomsInUse = false;
  }
  _viewer.addEventListener(et.MODEL_UNLOADED_EVENT, onModelUnloaded);

  this.dtor = function () {
    _viewer.removeEventListener(et.MODEL_UNLOADED_EVENT, onModelUnloaded);
    _viewer = null;

    for (var i = 0; i < NUM_WORKERS; i++) {
      _workers[i].clearAllEventListenerWithIntercept();
      _workers[i].terminate();
    }
  };

  // function to handle messages from OtgGeomWorker (posted in onGeometryLoaded)
  function handleMessage(msg) {

    if (!msg.data) {
      return;
    }

    if (msg.data.error) {
      var error = msg.data.error;

      // get hash for which request failed
      var hash = error.args ? error.args.hash : undefined;

      // inform affected clients.
      if (hash) {
        _geoms.set(hash, error); //create an error entry in the cache
        _this.fireEvent({ type: MESH_FAILED_EVENT, error: error });

        console.warn("Error loading mesh", hash);
      }

      delete _geomHash2Requests[error.hash];

      // track number of requests in progress
      _requestsInProgress--;

    } else {

      var meshlist = msg.data;
      for (var i = 0; i < meshlist.length; i++) {

        var mdata = meshlist[i];

        if (mdata.hash && mdata.mesh) {
          // convert goemetry data to GeometryBuffer (result is mdata.geometry)
          BufferGeometryUtils.meshToGeometry(mdata);

          // add geom to cache
          var hash = mdata.hash;
          var geom = mdata.geometry;
          _geoms.set(hash, geom);

          // track summed cache size in bytes
          _this.byteSize += geom.byteSize;

          // free old unused geoms if necessary
          _this.cleanup();

          // pass geometry to all receiver callbacks
          _this.fireEvent({ type: MESH_RECEIVE_EVENT, geom: geom });

          delete _geomHash2Requests[mdata.hash];
        }

        // track number of requests in progress
        _requestsInProgress--;
      }
    }

    if (_waitingTasks.length && !_timeout) {
      _timeout = setTimeout(processQueuedItems, 0);
    }

  }

  for (var i = 0; i < NUM_WORKERS; i++) {
    _workers[i].addEventListenerWithIntercept(handleMessage);
  }


  function assignWorkerForGeomId(geomId) {

    if (!_ranges || !_ranges.length || !geomId)
    return 0 | Math.random() * NUM_WORKERS;

    var lo = 0;
    var hi = _ranges.length - 1;
    var range;

    do {
      var mid = 0 | (lo + hi) / 2;
      range = _ranges[mid];

      if (range.geomStart > geomId)
      hi = mid - 1;else
      if (range.geomEnd <= geomId)
      lo = mid + 1;else

      break;

    } while (lo <= hi);

    if (range.geomStart <= geomId && range.geomEnd > geomId)
    return range.workerId;else
    {
      console.error("Range not found", geomId);
      return -1; //should not happen
    }
  }


  this.initWorker = function (modelUrn) {

    //Tell each worker which ranges of the geometry pack it's responsible for.
    for (var i = 0; i < NUM_WORKERS; i++) {

      var msg = {
        operation: "INIT_WORKER_OTG",
        workerId: i,
        authorizeUrns: [modelUrn] };


      _workers[i].doOperation(initLoadContextGeomCache(msg));
    }
  };

  this.updateMruTimestamps = function () {

    //Tell each worker which ranges of the geometry pack it's responsible for.
    for (var i = 0; i < NUM_WORKERS; i++) {

      var msg = {
        operation: "UPDATE_MRU_TIMESTAMPS_OTG",
        workerId: i };


      _workers[i].doOperation(initLoadContextGeomCache(msg));
    }

  };


  /**  Get a geometry from cache or load it.
      *    @param {string}   url         - full request url of the geometry/ies resource
      *    @param {boolean}  isCDN       - whether the URL is pointing to a public edge cache endpoint
      *    @param {string}   geomHash    - hash key to identify requested geometry/ies
      *    @param {int} geomIdx          - the geometry ID/index in the model's geometry hash list (optional, pass 0 to skip use of geometry packs)
      *    @param {string}   queryParams - additional param passed to file query
      */
  this.requestGeometry = function (url, isCDN, geomHash, geomIdx, queryParams) {

    // if this geometry is in memory, just return it directly
    var geom = _geoms.get(geomHash);
    if (geom && geom.args) {
      //it failed to load previously
      this.fireEvent({ type: MESH_FAILED_EVENT, error: geom, repeated: true });
      return;
    } else if (geom) {
      //it was already cached
      this.fireEvent({ type: MESH_RECEIVE_EVENT, geom: geom });
      return;
    }

    // if geometry is already loading, just increment
    // the request counter.
    var task = _geomHash2Requests[geomHash];
    if (task && task.refcount) {
      task.importanceNeedsUpdate = true;
      task.refcount++;
      return;
    }

    // geom is neither in memory nor loading.
    // we have to request it.
    var msg = {
      operation: "LOAD_GEOMETRY_OTG",
      url: url,
      isCDN: isCDN,
      hash: geomHash,
      geomIdx: geomIdx,
      queryParams: queryParams,
      importance: 0.0,
      importanceNeedsUpdate: true, // compute actual importance later in updatePriorities
      refcount: 1 };


    _waitingTasks.push(msg);
    _geomHash2Requests[geomHash] = msg;

    if (!_timeout) {
      _timeout = setTimeout(processQueuedItems, 0);
    }

  };


  function processQueuedItems() {

    var howManyCanWeDo = _maxRequestsPerWorker * NUM_WORKERS - _requestsInProgress;

    if (howManyCanWeDo === 0) {
      _timeout = setTimeout(processQueuedItems, 30);
      return;
    }

    // recompute importance for each geometry and sort queue by decreasing priority
    var priorityUpdateFinished = _this.updateRequestPriorities();

    // Restrict number of simultaneous requests until our priorities are fully updated
    if (!priorityUpdateFinished) {
      howManyCanWeDo = Math.min(howManyCanWeDo, 10 * NUM_WORKERS);
    }

    var msgPerWorker = [];
    var tasksAdded = 0;
    var idx = 0;

    while (idx < _waitingTasks.length && tasksAdded < howManyCanWeDo) {

      var task = _waitingTasks[idx++];

      //Find which worker thread is preferred for the task
      //If we are using a geometry pack to accelerate loading for small meshes,
      //each worker has a specific piece of the overall geometry pack
      var whichWorker = assignWorkerForGeomId(task.geomIdx);
      var msg = msgPerWorker[whichWorker];

      if (!msg) {
        msg = {
          operation: "LOAD_GEOMETRY_OTG",
          urls: [task.url],
          isCDN: task.isCDN,
          hashes: [task.hash],
          geomIds: [task.geomIdx],
          queryParams: task.queryParams };


        msgPerWorker[whichWorker] = msg;
      } else {
        msg.urls.push(task.url);
        msg.hashes.push(task.hash);
        msg.geomIds.push(task.geomIdx);
      }

      tasksAdded++;
    }

    _waitingTasks.splice(0, idx);

    for (var i = 0; i < msgPerWorker.length; i++) {
      var msg = msgPerWorker[i];
      if (msg) {
        // send request to worker
        _workers[i].doOperation(initLoadContextGeomCache(msg));
        _requestsInProgress += msg.urls.length;
      }
    }

    _timeout = undefined;
  }

  // remove all open requests of this client
  // input is a map whose keys are geometry hashes
  this.cancelRequests = function (geomHashMap) {

    for (var hash in geomHashMap) {
      var task = _geomHash2Requests[hash];

      if (task)
      task.refcount--;
      /*
                       if (task.refcount === 1) {
                           delete _geomHash2Requests[hash];
                       }*/
    }

    var hiPrioList = [];
    for (var i = 0; i < _waitingTasks.length; i++) {
      var t = _waitingTasks[i];
      if (_geomHash2Requests[t.hash].refcount)
      hiPrioList.push(t);else

      delete _geomHash2Requests[t.hash];
    }

    //TODO: perhaps we can leave requests with refcount = 0 in the queue
    //but sort the queue based on refcount so that those get deprioritized
    _waitingTasks = hiPrioList;

    // TODO: To make switches faster, we should also inform the worker thread,
    //       so that it doesn't spend too much time with loading geometries that noone is waiting for.
  };

  // To prioritize a geometry, we track the bbox surface area of all fragments using it.
  //
  // For this, this function must be called for each new loaded fragment.
  //  @param {RenderModel} model
  //  @param {number}      fragId
  this.updateGeomImportance = function () {

    var tmpBox = new Box3();

    return function (model, fragId) {

      // get geom and bbox of this fragment
      var frags = model.getFragmentList();
      var geom = frags.getGeometry(fragId);
      frags.getWorldBounds(fragId, tmpBox);

      // Geoms may be null by design, if the original geometry was degenerated before OTG translation
      if (!geom) {
        return;
      }

      var oldImportance = geom.importance || 0;
      var fragImportance = getBoxSurfaceArea(tmpBox);
      geom.importance = Math.max(oldImportance, fragImportance);
    };
  }();

  this.cleanup = function () {

    // {BufferGeometry[]} - reused tmp-array. Must always be cleared at function end to avoid geom leaking.
    var unusedGeoms = [];

    return function () {

      if (this.byteSize < _maxMemory) {
        return;
      }

      // get array of models in memory
      var mq = _viewer.impl.modelQueue();
      var loadedModels = mq.getModels().concat(_viewer.getHiddenModels());

      if (_allGeomsInUse) {
        // On last run, we discovered that we have no unused geometries anymore. As long as no model
        // is unloaded, we should not retry. Otherwise, we would waste a lot of time for each single new geometry.
        // Note that this has huge performance impact, because rerunning for each geometry is extremely slow.
        return;
      }

      // mark all geometries in-use with latest time-stamp
      // We consider a geometry as in-use if it is currently loaded by the viewer
      _timeStamp++;
      for (var i = 0; i < loadedModels.length; i++) {

        // get geom hashes for this model
        var model = loadedModels[i];
        var data = model.getData();
        if (!model.isOTG()) {
          // if this is not an Oscar model, it cannot contain shared geoms.
          // We can skip it.
          continue;
        }
        // For OTG models, we can assume that data is an OtgPackage and contains hashes

        // hasesh may by null for empty models                     
        var hashes = data.geomMetadata.hashes;
        if (!hashes) {
          continue;
        }

        // update timestamp for all geoms that are referenced by the hash list (Note that hashes may be null for empty models)
        var hashCount = hashes.length / data.geomMetadata.byteStride;
        for (var j = 1; j < hashCount; j++) {// start at 1, because index 0 is reserved value for invalid geomIndex

          // If the geom for this hash is in cache, update its tiemstamp
          var hash = data.getGeometryHash(j);
          var geom = _geoms.get(hash);
          if (geom) {
            geom.timeStamp = _timeStamp;
          }
        }
      }

      // verify that no geom is leaked in the reused tmp array
      if (unusedGeoms.length > 0) {
        console.warn("OtgGeomCache.cleanup(): array must be empty");
      }

      // Collect all unused geoms, i.e., all geoms that do not have the latest timeStamp
      for (var hash in _geoms) {
        var geom = _geoms.get(hash);
        if (geom.timeStamp !== _timeStamp) {
          unusedGeoms.push(geom);
        }
      }

      // Sort unused geoms by ascending importance
      unusedGeoms.sort(compareGeomsByImportance);

      // Since cleanup is too expensive to run per geometry,
      // we always remove a bit more than strictly necessary,
      // so that we can load some more new geometries before we have to
      // run cleanup again.
      var targetMem = _maxMemory - _minCleanup;

      // Remove geoms until we reach mem target
      var i = 0;
      for (; i < unusedGeoms.length && this.byteSize >= targetMem; i++) {

        var geom = unusedGeoms[i];

        // remove it from cache
        delete _geoms["delete"](geom.hash);

        // update mem consumption. Note that we run this only for geoms that
        // are not referenced by any RenderModel in memory, so that removing them
        // should actually free memory.
        this.byteSize -= geom.byteSize;

        // Dispose GPU mem.
        // NOTE: In case we get performance issues in Chrome, try commenting this out
        // (see hack in GeometryList.dispose)
        geom.dispose();
      }

      if (i === unusedGeoms.length) {
        // No more unused geometries. Any subsequent attempt to cleanup will fail until
        // the next model unload.
        _allGeomsInUse = true;
      }

      // clear reused temp array. Note that it's essential to do this immediately. Otherwise,
      // the geoms would be leaked until next cleanup.
      unusedGeoms.length = 0;
    };
  }();

  // Helper function to compare two THREE.Vector3
  function fuzzyEquals(a, b, eps) {
    return (
      Math.abs(a.x - b.x) < eps &&
      Math.abs(a.y - b.y) < eps &&
      Math.abs(a.z - b.z) < eps);

  }

  // Checks if the camera has significantly changed 
  function checkCameraChanged(newPos, newTarget) {

    var Tolerance = 0.01;
    if (fuzzyEquals(_lastCamPos, newPos, Tolerance) &&
    fuzzyEquals(_lastCamTarget, newTarget, Tolerance))
    {
      // no change
      return false;
    }

    _lastCamPos.copy(newPos);
    _lastCamTarget.copy(newTarget);
    return true;
  }

  // Checks if the set of visible models has changed
  function checkModelsChanged() {

    // get currently visible models
    var mq = _viewer.impl.modelQueue();
    var models = mq.getModels();

    var changed = false;

    // Check if number of visible models changed
    if (models.length != _lastVisibleModelIds.length) {
      _lastVisibleModelIds.length = models.length;
      changed = true;
    }

    // Check if any element of visible models have changed
    for (var i = 0; i < models.length; i++) {
      var idOld = _lastVisibleModelIds[i];
      var idNew = models[i].id;
      if (idOld !== idNew) {
        _lastVisibleModelIds[i] = idNew;
        changed = true;
      }
    }

    return changed;
  }

  // Checks for any relevant changes that require to recompute request priorities.
  // If found, all requests are marked by the importanceNeedsUpdate flag.
  function validateRequestPriorities() {

    // get current camera pos/target
    var cam = _viewer.impl.camera;
    var pos = cam.position;
    var target = cam.target;

    // check if camera or set of visible model have changed
    var cameraChanged = checkCameraChanged(pos, target);
    var modelsChanged = checkModelsChanged();

    if (cameraChanged || modelsChanged) {

      // invalidate all task priorities
      for (var i = 0; i < _waitingTasks.length; i++) {
        _waitingTasks[i].importanceNeedsUpdate = true;
      }
    }
  }

  this.updateRequestPriorities = function () {

    // We track the time consumed for priority updates. If it exceeds the limit,
    // we stop the updates and continue next cycle.
    var updateStartTime = performance.now();
    var TimeLimit = 10; // in ms

    // Mark requests as outdated if any relevant changes occurred
    validateRequestPriorities();

    var mq = _viewer.impl.modelQueue();
    var frustum = mq.frustum();
    var models = mq.getModels(); // all models (excluding the hidden ones - which will not considered for importance)

    // Make sure that FrustumIntersector is up-to-date.
    frustum.reset(_viewer.impl.camera);

    // indicates if we stopped due to timeout
    var timeOut = false;


    var useFullSort = _prevNumTasks === 0 || _waitingTasks.length - _prevNumTasks > 3000 || !_fullSortDone;
    _fullSortDone = !useFullSort;
    _prevNumTasks = _waitingTasks.length;

    // Update importance for each waiting request
    for (var i = 0; i < _waitingTasks.length; i++) {

      var task = _waitingTasks[i];

      // only do work for tasks that need it
      if (!task.importanceNeedsUpdate) {
        continue;
      }

      if (_urgentHashes[task.hash]) {
        task.importance = Infinity;
        continue;
      }

      //Don't check the timer on every spin through the loop
      //as it takes some time.
      if (i % 10 === 0) {
        var elapsed = performance.now() - updateStartTime;
        if (elapsed > TimeLimit) {
          timeOut = true;
          break;
        }
      }

      task.importanceNeedsUpdate = false;

      // reset importance to 0.0, because we accumulate frag importances below
      task.importance = 0.0;

      var sumImportances = 0.0;

      // find fragments of all visible models that use geomHash
      var geomHash = task.hash;
      for (var j = 0; j < models.length; j++) {
        var model = models[j];

        // we only deal with otg geometries
        if (!model.isOTG()) {
          continue;
        }

        // Note that we cannot use FragmentLists at this point, because FragmentLists only know about
        // fragments for which geometry is already loaded.
        // => We must use Otg package instead.
        var otg = model.myData;
        var frags = otg.fragments;
        var boxes = frags.boxes;

        // If the geomHash is used in this model, get its geom index
        var geomIndex = otg.geomMetadata.hashToIndex[geomHash];
        if (!geomIndex) {
          // geom is not used by this model
          continue;
        }

        // If all geometry of a model is loaded, mesh2frag will be deleted by OtgLoader.
        // But, this implies that this model cannot be waiting for any geometry. So we can just skip it.
        if (!frags.mesh2frag) {
          continue;
        }

        // Get list of fragments in 'model' that are using 'geomIndex' 
        var fragIds = frags.mesh2frag[geomIndex];

        if (typeof fragIds === 'number') {
          // single fragId
          var value = computeFragImportance(fragIds, boxes, frustum);
          sumImportances += value;

        } else if (Array.isArray(fragIds)) {
          // multiple fragIds
          for (var k = 0; k < fragIds.length; k++) {
            var fragId = fragIds[k];

            var value = computeFragImportance(fragId, boxes, frustum);
            sumImportances += value;
          }
        }
      }

      task.importance = sumImportances;

      if (!useFullSort) {
        //Move the task to the correct spot in the list based on its
        //new importance. This is basically insertion sort, but assuming
        //the task list is nearly sorted already it should be quick
        var j = i;
        while (j > 0 && sumImportances > _waitingTasks[j - 1].importance) {
          _waitingTasks[j] = _waitingTasks[j - 1];
          j--;
        }
        _waitingTasks[j] = task;
      }
    }

    if (useFullSort && !timeOut) {
      // sort task queue by descending request priority
      _waitingTasks.sort(compareRequests);
      _fullSortDone = true;
    }

    // return true if all request priorities are up-to-date and sorted
    return !timeOut;
  };

  // Wait for specific hashes and push their priority to finish faster. 
  //
  // Note: This function does not trigger own requests, i.e. can only be used for hashes of models
  //		   that are currently loading.
  //
  //  @param {Object} hashMap          - keys specify hashes. All keys with hashMap[key]===true will be loaded. 
  //  @param {function(Object)} onDone - called with hashMap. hashMap[hash] will contain the geometry.
  this.waitForGeometry = function (hashMap, onDone) {

    // track how many of our geoms are finished
    var geomsDone = 0;
    var geomsTodo = 0;

    // Push priority of all hashes that we want
    for (var hash in hashMap) {
      if (hashMap[hash] === true) {
        _urgentHashes[hash] = true;
        geomsTodo++;
      }
    }

    // avoid hanging if hashMap is empty
    if (geomsTodo === 0) {
      if (hashMap) {
        onDone(hashMap);
        return;
      }
    }

    // Sort all related tasks instantly to the front. This would happen automatically, 
    // but a while later due to the gradual importance update.
    for (var i = 0; i < _waitingTasks.length; i++) {
      var task = _waitingTasks[i];
      if (_urgentHashes[task.hash]) {
        task.importance = Infinity;
      }
    }
    _waitingTasks.sort(compareRequests);
    processQueuedItems();

    function onGeomDone(hash, geom) {
      // If a geometry is not loading anymore, its priority has no relevance anymore.
      // Note that this is generally true - even if we didn't set the priority in this waitForGeometry call. 
      delete _urgentHashes[hash];

      // Only care for geometries that we need to fill the hashMap values 
      if (!hashMap[hash] === true) {
        return;
      }

      hashMap[hash] = geom;

      // check if all done
      geomsDone++;
      if (geomsDone < geomsTodo) {
        return;
      }

      // cleanup listeners
      _this.removeEventListener(MESH_RECEIVE_EVENT, onGeomReceived);
      _this.removeEventListener(MESH_FAILED_EVENT, onGeomFailed);

      onDone(hashMap);
    }

    function onGeomReceived(event) {onGeomDone(event.geom.hash, event.geom);}
    function onGeomFailed(event) {onGeomDone(event.error.args.hash, undefined);}

    this.addEventListener(MESH_RECEIVE_EVENT, onGeomReceived);
    this.addEventListener(MESH_FAILED_EVENT, onGeomFailed);

    // Don't wait forever for any meshes that were already loaded
    for (hash in hashMap) {
      var geom = _geoms.get(hash);
      if (geom) {
        onGeomDone(hash, geom);
      }
    }
  };

  this.getGeometry = function (hash) {
    return _geoms.get(hash);
  };
}

EventDispatcher.prototype.apply(OtgGeomCache.prototype);