import { enumMeshTriangles, enumMeshLines, enumMeshVertices } from './VertexEnumerator';
import * as THREE from "three";

var inverseMatrix;
var ray;

var vA;
var vB;
var vC;

function init_three() {

  if (!inverseMatrix) {
    inverseMatrix = new THREE.Matrix4();
    ray = new THREE.Ray();

    vA = new THREE.Vector3();
    vB = new THREE.Vector3();
    vC = new THREE.Vector3();
  }
}

function meshRayCast(mesh, raycaster, intersects) {

  init_three();

  var geometry = mesh.geometry;

  if (!geometry)
  return;

  var material = mesh.material;

  var side = material ? material.side : THREE.FrontSide;

  inverseMatrix.getInverse(mesh.matrixWorld);
  ray.copy(raycaster.ray).applyMatrix4(inverseMatrix);

  var precision = raycaster.precision;
  var intersectionPoint, distance;

  enumMeshTriangles(geometry, function (vA, vB, vC, a, b, c) {
    if (side === THREE.BackSide) {

      intersectionPoint = ray.intersectTriangle(vC, vB, vA, true);

    } else {

      intersectionPoint = ray.intersectTriangle(vA, vB, vC, side !== THREE.DoubleSide);

    }

    if (intersectionPoint === null) return;

    intersectionPoint.applyMatrix4(mesh.matrixWorld);

    distance = raycaster.ray.origin.distanceTo(intersectionPoint);

    if (distance < precision || distance < raycaster.near || distance > raycaster.far) return;

    intersects.push({

      distance: distance,
      point: intersectionPoint,
      face: new THREE.Face3(a, b, c, THREE.Triangle.normal(vA, vB, vC)),
      faceIndex: null,
      fragId: mesh.fragId,
      dbId: mesh.dbId });


  });

}


function lineRayCast(mesh, raycaster, intersects) {

  init_three();

  var geometry = mesh.geometry;

  if (!geometry)
  return;

  var precision = raycaster.linePrecision;
  if (mesh.isWideLine) {
    if (mesh.material.linewidth) {
      precision = mesh.material.linewidth;
    } else if (mesh.geometry.lineWidth) {
      precision = mesh.geometry.lineWidth;
    }
  }
  var precisionSq = precision * precision;

  inverseMatrix.getInverse(mesh.matrixWorld);
  ray.copy(raycaster.ray).applyMatrix4(inverseMatrix);

  var interSegment = new THREE.Vector3();
  var interRay = new THREE.Vector3();

  if (geometry instanceof THREE.BufferGeometry) {

    enumMeshLines(geometry, function (vStart, vEnd) {

      var distance, distSq;

      ray.distanceSqToSegment(vStart, vEnd, interRay, interSegment);

      interSegment.applyMatrix4(mesh.matrixWorld);
      interRay.applyMatrix4(mesh.matrixWorld);

      distSq = interSegment.distanceToSquared(interRay);

      if (distSq > precisionSq) return;

      distance = raycaster.ray.origin.distanceTo(interSegment);

      if (distance < raycaster.near || distance > raycaster.far) return;

      intersects.push({

        distance: distance,
        // What do we want? intersection point on the ray or on the segment??
        // point: raycaster.ray.at( distance ),
        point: interSegment,
        face: null,
        faceIndex: null,
        fragId: mesh.fragId,
        dbId: mesh.dbId });


    });


  }
}

/// c.f. THREE.PointCloud.prototype.raycast()
function pointRayCast(mesh, raycaster, intersects) {

  init_three();

  var geometry = mesh.geometry;
  if (!geometry)
  return;

  inverseMatrix.getInverse(mesh.matrixWorld);
  ray.copy(raycaster.ray).applyMatrix4(inverseMatrix);

  var precision = raycaster.precision;

  var pickRadius = raycaster.params.PointCloud.threshold;
  if (!pickRadius) pickRadius = 1;
  pickRadius *= Math.max(3, geometry.pointSize); // small point sizes are too hard to pick!
  pickRadius /= 4;

  if (geometry instanceof THREE.BufferGeometry) {

    enumMeshVertices(geometry, function (point) {
      // points are drawn as squares, but treat them as circles
      // to save having to calculate the orientation
      var distanceToRay = ray.distanceToPoint(point);
      if (distanceToRay > pickRadius) {
        return;
      }

      var intersectionPoint = ray.closestPointToPoint(point);
      if (intersectionPoint === null) return;
      intersectionPoint.applyMatrix4(mesh.matrixWorld);

      var distance = raycaster.ray.origin.distanceTo(intersectionPoint);
      if (distance < precision || distance < raycaster.near || distance > raycaster.far) {
        return;
      }

      intersects.push({

        distance: distance,
        point: point,
        face: null,
        faceIndex: null,
        fragId: mesh.fragId,
        dbId: mesh.dbId });



    });

  } else {
    // not implemented - other geometry types
  }

}


function rayCast(mesh, raycaster, intersects) {

  if (mesh.isLine || mesh.isWideLine)
  lineRayCast(mesh, raycaster, intersects);else
  if (mesh.isPoint)
  pointRayCast(mesh, raycaster, intersects);else

  meshRayCast(mesh, raycaster, intersects);

}


function intersectObjectRec(object, raycaster, intersects, recursive) {

  if (object instanceof THREE.Mesh)
  rayCast(object, raycaster, intersects); //use our extended impl in case of Mesh.
  else
    object.raycast(raycaster, intersects); //fall back to normal THREE.js impl

  if (recursive === true) {

    var children = object.children;

    for (var i = 0, l = children.length; i < l; i++) {

      intersectObjectRec(children[i], raycaster, intersects, true);

    }

  }

}

var descSort = function descSort(a, b) {
  return a.distance - b.distance;
};

function intersectObject(object, raycaster, intersects, recursive) {
  intersectObjectRec(object, raycaster, intersects, recursive);
  intersects.sort(descSort);
}


export var VBIntersector = {
  meshRayCast: meshRayCast,
  lineRayCast: lineRayCast,
  rayCast: rayCast,
  intersectObject: intersectObject };