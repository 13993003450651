
import * as THREE from "three";


var normals = [
new THREE.Vector3(1, 0, 0),
new THREE.Vector3(0, 1, 0),
new THREE.Vector3(0, 0, 1),
new THREE.Vector3(-1, 0, 0),
new THREE.Vector3(0, -1, 0),
new THREE.Vector3(0, 0, -1)];


var plane = new THREE.Plane();

// Returns an array of vector4 values that describe the cutplanes needed to apply a given sectionBox
function box2CutPlanes(box, transform) {

  var planeVecs = [];

  for (var i = 0; i < normals.length; i++) {

    plane.normal.copy(normals[i]);
    var onPlane = i < 3 ? box.max : box.min;
    plane.constant = -plane.normal.dot(onPlane);

    if (transform) {
      plane.applyMatrix4(transform);
    }

    planeVecs.push(new THREE.Vector4(plane.normal.x, plane.normal.y, plane.normal.z, plane.constant));
  }

  return planeVecs;
}

// Compute pixels per unit parameter for 2D materials.
//  @param {Camera}  camera
//  @param {bool}    is2d
//  @param {Box3}    worldBox      - worldBox of all visible geometry
//  @param {number}  deviceHeight  - canvas height * pixelRatio
//  @param {Vector4} [cutPlane]    - Optional: A cutplane in 3D containg 2D geometry. If specified, its camera distance is
//                                   considered to determine pixelsPerUnit.
//  @param {Vector3} [modelBox]    - Only for 2D: The min-plane of this box is assumed as ground plane
function getPixelsPerUnit(camera, is2d, worldBox, deviceHeight, cutPlane, modelBox) {
  var distance, pixelsPerUnit;

  if (is2d) {

    //Here we base pixel scale on the point at the center of the view.
    //However, this might not always be the most appropriate point,
    //e.g. at oblique angles or when the drawing is off to one side.
    //It might make more sense to base the scale on the distance of the
    //camera to the nearest part of the world bounding box, which requires
    //a more generic ray-aabb test.
    var worldUp = 'z'; // in 2d, world-up is aways Z
    var groundPt = intersectGroundViewport(new THREE.Vector3(0, 0, 1), camera, worldUp, modelBox);

    if (groundPt)
    distance = camera.position.distanceTo(groundPt);else

    distance = camera.position.distanceTo(worldBox.center()); //degenerate case: camera direction is parallel to the ground plane

    //NOTE: In case of ortho projection, we set FOV such that tan(fov/2) = 0.5,
    //so here we don't need separate code path for ortho.
    pixelsPerUnit = deviceHeight / (2 * distance * Math.tan(THREE.Math.degToRad(camera.fov * 0.5)));
  } else {

    var pt;
    if (cutPlane) {
      var p = cutPlane;

      var dir = camera.target.clone().sub(camera.position).normalize();
      var denominator = dir.dot(p);

      if (denominator === 0)
      pt = worldBox.center();else
      {
        var t = -(camera.position.clone().dot(p) + p.w) / denominator;
        pt = worldBox.clampPoint(dir.multiplyScalar(t).add(camera.position));
      }
    } else {
      pt = worldBox.center();
    }

    distance = camera.position.distanceTo(pt);

    //NOTE: In case of ortho projection, we set FOV such that tan(fov/2) = 0.5,
    //so here we don't need separate code path for ortho.
    pixelsPerUnit = deviceHeight / (2 * distance * Math.tan(THREE.Math.degToRad(camera.fov * 0.5)));
  }

  return pixelsPerUnit;
}

// Note: The camera world matrix must be up-to-date
//  @param {Vec3d}  vpVec         - ray direction in viewport coords
//  @param {Camera} camera
//  @param {string} worldUp       - main axis of up-vector, i.e., "x", "y", or "z".
//  @param {bool}   is2d          - In 2d, worldUp setting is ignored and we always use "z".
//  @param {Box3}   modelBox      - Used to derive min-elevation (where ground is assumed)
function intersectGroundViewport(vpVec, camera, worldUpName, modelBox) {

  var vector = vpVec;

  // set two vectors with opposing z values
  vector.z = -1.0;
  var end = new THREE.Vector3(vector.x, vector.y, 1.0);
  vector = vector.unproject(camera);
  end = end.unproject(camera);

  // find direction from vector to end
  end.sub(vector).normalize();

  var dir = end;

  //Is the direction parallel to the ground plane?
  //Then we fail.
  if (Math.abs(dir[worldUpName]) < 1e-6)
  return null;

  var rayOrigin;
  if (camera.isPerspective) {
    rayOrigin = camera.position;
  } else
  {
    rayOrigin = vector;
  }

  var baseElev = modelBox ? modelBox.min[worldUpName] : 0;
  var distance = (baseElev - rayOrigin[worldUpName]) / dir[worldUpName];

  //2D drawing, intersect the plane
  dir.multiplyScalar(distance);
  dir.add(rayOrigin);

  return dir;
}


/**
   * Returns a new matrix that transforms points from the loaded 2D model
   * into a normalized coordinate space [0..1].
   *
   * @param {THREE.Box3} bbox - Optional - Compute the normalizing matrix according to the given bounding box.
   *
   * @returns {THREE.Matrix4}
   * @private
   */
function getNormalizingMatrix(model, bbox) {

  bbox = bbox || model.getData().bbox;

  var trans = new THREE.Matrix4();
  trans.makeTranslation(-bbox.min.x, -bbox.min.y, -bbox.min.z);

  var delta = new THREE.Vector3(0, 0, 0).subVectors(bbox.max, bbox.min);
  var scale = new THREE.Matrix4();
  scale.makeScale(1 / delta.x, 1 / delta.y, 1);

  var res = new THREE.Matrix4();
  res.multiplyMatrices(scale, trans);
  return res;
}



export var SceneMath = {
  box2CutPlanes: box2CutPlanes,
  getPixelsPerUnit: getPixelsPerUnit,
  intersectGroundViewport: intersectGroundViewport,
  getNormalizingMatrix: getNormalizingMatrix };