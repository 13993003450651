
import { Extension } from "../../src/application/Extension";

/**
                                                              * Use its `activate()` method to open the Properties UI.
                                                              * 
                                                              * The extension id is: `Autodesk.PropertiesManager`
                                                              * 
                                                              * @example
                                                              *   viewer.loadExtension('Autodesk.PropertiesManager')
                                                              * 
                                                              * @memberof Autodesk.Viewing.Extensions
                                                              * @alias Autodesk.Viewing.Extensions.PropertiesManagerExtension
                                                              * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
                                                              * @constructor
                                                              */
export function PropertiesManagerExtension(viewer, options) {
  Extension.call(this, viewer, options);
  this.viewer = viewer;
  this.options = options;
  this.propertyPanel = this.viewer.getPropertyPanel(true);
  this.name = "propertiesmanager";
}
PropertiesManagerExtension.prototype = Object.create(Extension.prototype);
PropertiesManagerExtension.prototype.constructor = PropertiesManagerExtension;

var proto = PropertiesManagerExtension.prototype;

/**
                                                   * Opens the Properties UI.
                                                   * 
                                                   * @memberof Autodesk.Viewing.Extensions.PropertiesManagerExtension
                                                   * @alias Autodesk.Viewing.Extensions.PropertiesManagerExtension#activate
                                                   */
proto.activate = function () {
  if (!this.activeStatus) {
    this.propertyPanel.setVisible(true);
    this.activeStatus = true;
  }
  return true;
};

/**
    * Closes the Properties UI.
    * 
    * @memberof Autodesk.Viewing.Extensions.PropertiesManagerExtension
    * @alias Autodesk.Viewing.Extensions.PropertiesManagerExtension#deactivate
    */
proto.deactivate = function () {
  if (this.activeStatus) {
    this.propertyPanel.setVisible(false);
    this.activeStatus = false;
  }
  return true;
};