
import * as et from "./EventTypes";
import { logger } from "../logger/Logger";

/**
                                            * Application preferences.
                                            *
                                            * Optionally uses web storage.
                                            *
                                            * Each preference value can have tags associated to them. Developer supported tags are:
                                            * - 'ignore-producer'
                                            * - 'no-storage'
                                            * - '2d'
                                            * - '3d'
                                            *
                                            * Use tag 'ignore-producer' in extensions to avoid having developer-defined
                                            * render settings overridden by the loaded file.
                                            *
                                            * Use tag 'no-storage' in extensions to avoid having User Preferences (from Settings Panel) override
                                            * default or developer-defined preferences. Useful for render settings.
                                            *
                                            * Preferences may apply to all model types, only 2D models (with tag '2d') or 3D models only (with tag '3d').
                                            *
                                            * @constructor
                                            * @param {Autodesk.Viewing.Viewer3D} viewer - Viewer instance.
                                            * @param {object} options - Contains configuration parameters used to do initializations.
                                            * @param {boolean} [options.localStorage] - Whether values get stored and loaded back
                                            * from localStorage. Defaults to `true`.
                                            * @param {string} [options.prefix] - A string to prefix preference names in web storage.
                                            * Defaults to `'Autodesk.Viewing.Preferences.'`.
                                            * @alias Autodesk.Viewing.Private.Preferences
                                            */
export function Preferences(viewer, options) {

  // Backwards compatibility for when the 2nd argument was 'prefix' string
  if (typeof options === 'string') {
    options = {
      prefix: options };

  }
  if (!options) {
    options = {};
  }

  if (!options.prefix) {
    options.prefix = 'Autodesk.Viewing.Preferences.';
  }
  if (!options.hasOwnProperty('localStorage')) {
    options.localStorage = true;
  }

  // from stackoverflow:
  // http://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an
  //
  function isLocalStorageSupported() {
    var testKey = options.prefix + 'test';
    try {
      var storage = window.localStorage; // This may assert if browsers disallow sites from setting data.
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;

    } catch (error) {
      return false;
    }
  }

  var defaults = {}, // Default values
  callbacks = {}, // Changed and Reset listeners
  tags = {},
  useLocalStorage = options.localStorage && isLocalStorageSupported(),
  that = this;

  // TODO: callbacks should be array, not single
  // Would need to deal with issue of registering same callback twice
  //
  viewer.addEventListener(et.PREF_CHANGED_EVENT, function (event) {
    var callbacksForName = callbacks[event.name];
    if (callbacksForName) {
      var callback = callbacksForName.changed;
      if (callback) {
        callback(event.value);
      }
    }
  });

  viewer.addEventListener(et.PREF_RESET_EVENT, function (event) {
    var callbacksForName = callbacks[event.name];
    if (callbacksForName) {
      var callback = callbacksForName.reset;
      if (callback) {
        callback(event.value);
      }
    }
  });

  /**
       * Get/set preference value in web storage.
       * No-Op if tag 'no-storage' is associated to the name.
       * @param {string} name - Preference name.
       * @param {*} [value] - Preference value.
       * @returns {*} Preference value or undefined if not available.
       * @private
       */
  function webStorage(name, value) {
    if (useLocalStorage) {

      // Avoid storage for 'no-storage' tags
      if (that.hasTag(name, 'no-storage')) {
        return undefined;
      }

      // Prefix our names, so we don't pollute the localStorage of the embedding application
      var prefixedName = options.prefix + name;

      if (typeof value !== "undefined") {
        // If value is specified, we set this value in localStorage
        window.localStorage[prefixedName] = value;

      } else {
        // If no value is specified we return the value from localStorage
        value = window.localStorage[prefixedName];
      }
      return value;
    }
    return undefined;
  }

  /**
     * Adds a preference name + default value, tries to load value from web storage.
     * @param {string} name
     * @param {*} defaultValue
     * @private
     */
  function addPref(name, defaultValue) {
    if (typeof name !== 'string' || typeof that[name] === 'function') {
      logger.log('Preferences: invalid name=' + name);
      return;
    }

    // Use default if nothing in web storage.
    //
    var value = webStorage(name);
    var ok = false;

    if (value !== undefined) {
      try {
        value = JSON.parse(value);
        ok = true;
      } catch (e) {
      }
    }
    that[name] = ok ? value : defaultValue;
    tags[name] = {};
  }

  /**
     * Load preference values from web storage/defaults.
     * @param {object} defaultValues - Preference names and their default values.
     */
  this.load = function (defaultValues) {
    defaults = defaultValues;
    for (var name in defaults) {
      if (defaults.hasOwnProperty(name)) {
        addPref(name, defaults[name]);
      }
    }
  };

  /**
      * Adds a tag to the specified preferences.
      * These are used by reset().
      * @param {string} tag
      * @param {string[]|string} [names] - Preference names, default all preferences.
      */
  this.tag = function (tag, names) {
    if (tag) {
      if (!names) {
        names = Object.keys(defaults);
      } else if (!Array.isArray(names)) {
        names = [names];
      }
      for (var i = 0; i < names.length; ++i) {
        if (tags[names[i]])
        tags[names[i]][tag] = true;
      }
    }
  };

  /**
      * Removes a tag from the specified preferences.
      * These are used by reset().
      * @param {string} tag
      * @param {string[]|string} [names] - Preference names, default all preferences.
      */
  this.untag = function (tag, names) {
    if (tag) {
      if (!names) {
        names = Object.keys(defaults);
      } else if (!Array.isArray(names)) {
        names = [names];
      }
      for (var i = 0; i < names.length; ++i) {
        if (tags[names[i]])
        tags[names[i]][tag] = false;
      }
    }
  };

  /**
      * Checks whether a tag is associated to a name
      * @param {string} name - Preference name
      * @param {string} tag - The tag to check for
      */
  this.hasTag = function (name, tag) {
    var nameKey = tags[name];
    if (nameKey) {
      return nameKey[tag] === true;
    }
    return false;
  };

  /**
      * Adds a new preference name + default value.
      * This preference was not previously loaded via load().
      * @param {string} name - Preference name.
      * @param {*} defaultValue - Preference default value.
      * @param {string[]|string} [tags] - Optional tags.
      * @returns {boolean} True if the preference was added.
      */
  this.add = function (name, defaultValue, tags) {
    if (defaults.hasOwnProperty(name)) {
      logger.log("Preferences: " + name + " already exists");

    } else {
      defaults[name] = defaultValue;
      addPref(name, defaultValue);

      if (tags) {
        if (!Array.isArray(tags)) {
          tags = [tags];
        }
        for (var i = 0; i < tags.length; ++i) {
          this.tag(tags[i], name);
        }
      }
      return true;
    }
    return false;
  };

  /**
      * Removes an existing preference.
      * @param {string} name - Preference name.
      * @param {boolean} [removeFromWebStorage=false] - True to clear the web storage entry for this preference.
      * @returns {boolean} True if the preference was removed.
      */
  this.remove = function (name, removeFromWebStorage) {
    if (defaults.hasOwnProperty(name)) {
      delete defaults[name];
      delete tags[name];
      delete this[name];

      if (removeFromWebStorage) {
        deleteFromWebStorage(name);
      }

      return true;
    }
    return false;
  };

  function deleteFromWebStorage(name) {
    if (useLocalStorage) {
      name = options.prefix + name;
      delete localStorage[name];
    }
  }

  /**
     * Reset preferences to default values.
     * If a tag is specified, then only certain preferences are reset.
     * @param {string} [tag] Optional tag.
     * @param {boolean} [include=true] True to reset only preferences with matching tags.
     */
  this.reset = function (tag, include) {
    if (tag && include === undefined) {
      include = true;
    }

    for (var name in defaults) {
      if (defaults.hasOwnProperty(name)) {
        if (tag) {
          var tagged = !!tags[name][tag];
          if (include && !tagged || !include && tagged) {
            continue;
          }
        }

        if (this.set(name, defaults[name], false)) {
          viewer.dispatchEvent({
            type: et.PREF_RESET_EVENT,
            name: name,
            value: this[name] });

        }

        deleteFromWebStorage(name);
      }
    }
  };

  /**
      * Get named preference value.
      * Shortcut: prefs[name]
      * @returns {*} Preference value.
      */
  this.get = function (name) {
    return this[name];
  };

  /**
      * Set named preference value.
      * Value is not persisted if tag 'no-storage' is set.
      * Do not use shortcut prefs[name] = value.
      * @param {string} name - Preference name.
      * @param {*} value - Preference value.
      * @param {boolean} [notify=true] - If true then PREF_CHANGED_EVENT is fired.
      * @returns {boolean} True if the value changed, false otherwise.
      */
  this.set = function (name, value, notify) {
    // Updates the cached value as well as the value in the web storage
    if (this[name] !== value) {
      this[name] = value;
      webStorage(name, value);

      if (notify === undefined || notify) {
        viewer.dispatchEvent({
          type: et.PREF_CHANGED_EVENT,
          name: name,
          value: value });

      }

      return true;
    }
    return false;
  };

  /**
      * Listen for preference changed and reset events.
      * @param {string} name - Preferences name.
      * @param {function} onChangedCallback - Function called when preferences are changed.
      * @param {function} onResetCallback - Function called when preferences are reset.
      */
  this.addListeners = function (name, onChangedCallback, onResetCallback) {
    callbacks[name] = { changed: onChangedCallback, reset: onResetCallback };
  };

  /**
      * Remove listeners for preference changed and reset events.
      * @param {string} name - Preferences name.
      */
  this.removeListeners = function (name) {
    if (callbacks[name] !== undefined) {
      delete callbacks[name];
    }
  };

  /**
      * Whether values are stored into browser's localStorage or read back from it.
      * @param {boolean} useIt - true to use browser's `localStorage`
      */
  this.setUseLocalStorage = function (useIt) {
    useLocalStorage = !!useIt;
  };
};