
import { SettingsPanel } from "./SettingsPanel";
import * as et from "../application/EventTypes";
import { logger } from "../logger/Logger";
import { LightPresets } from "../application/LightPresets";
import { i18n } from "../globalization/i18next";
import { isMobileDevice, touchStartToClick } from "../compat";
import { ViewerPanelMixin } from "./ViewerPanelMixin";
import { LightPresetThumbnails } from "./LightPresetThumbnails";

/**
                                                                  * Viewer3dSettings Tabs.
                                                                  *
                                                                  * These constants are used to define the tabs in the ViewerSettingsPanel.
                                                                  *
                                                                  * @enum {number}
                                                                  * @readonly
                                                                  */
export var ViewerSettingTab = {
  Navigation: "navigationtab",
  Configuration: "performancetab",
  Appearance: "appearance",
  Environment: "environment" };


var viewerSettingsPanelInstanceCnt = 0;

/**
                                         * ViewerSettingsPanel
                                         * This is a panel for displaying the settings for the viewer.
                                         * @class
                                         *
                                         * @param {Autodesk.Viewing.Viewer3D} viewer - the parent viewer
                                         * @param {string} model - whether it is 3d or 2d mode (acceptable strings: "2d", "3d")
                                         * @constructor
                                         */
export function ViewerSettingsPanel(viewer, model) {

  this.viewer = viewer;
  this.is3dMode = !model.is2d();
  this.visible = false;

  // Keeps track of external registered buttons.
  this._externalButtonIds = [];
  this._externalButtonsLabel = null;

  SettingsPanel.call(this, viewer.container, 'ViewerSettingsPanel' + viewer.id + '-' + viewerSettingsPanelInstanceCnt++, 'Settings',
  { width: 400, addFooter: false, heightAdjustment: 50 /*title-bar*/ + 40 /*tab-bar*/ + 20 /*footer*/ });
  this.container.classList.add('viewer-settings-panel');

  this.addTab(ViewerSettingTab.Configuration, "Configuration", { className: "performance" });
  this.addTab(ViewerSettingTab.Navigation, "Navigation", { className: "navigation" });
  this.addTab(ViewerSettingTab.Appearance, "Appearance", { className: "appearance" });
  if (this.is3dMode) {
    this.addTab(ViewerSettingTab.Environment, "Environment", { className: "environment" });
  }
  if (!this.is3dMode) {
    this.container.classList.add('for-2d-model');
  }

  this.createRestoreDefaultSettingsButton();

  this.modelPreferenceCount = 0;
  this.createNavigationPanel();
  this.createConfigurationPanel();
  this.createAppearancePanel();

  if (this.modelPreferenceCount) {
    logger.log('Model locked (' + this.modelPreferenceCount + ') render settings in UI.');
  }
  // Setting Configuration as the default tab
  this.selectTab(ViewerSettingTab.Configuration);

  this.footer = this.createFooter();
  this.createVersionLabel(this.footer);

  // Add events
  this.syncUI = this.syncUI.bind(this);
  viewer.addEventListener(et.RESTORE_DEFAULT_SETTINGS_EVENT, this.syncUI);
  viewer.addEventListener(et.VIEWER_STATE_RESTORED_EVENT, this.syncUI);
};

ViewerSettingsPanel.prototype = Object.create(SettingsPanel.prototype);
ViewerSettingsPanel.prototype.constructor = ViewerSettingsPanel;
ViewerPanelMixin.call(ViewerSettingsPanel.prototype);

/**
                                                       * Clean up when the viewer setting  is about to be removed.
                                                       * @override
                                                       */
ViewerSettingsPanel.prototype.uninitialize = function () {
  if (this.viewer) {
    this.viewer.removeEventListener(et.RESTORE_DEFAULT_SETTINGS_EVENT, this.syncUI);
    this.viewer.removeEventListener(et.VIEWER_STATE_RESTORED_EVENT, this.syncUI);
  }
  this.viewer = null;
  SettingsPanel.prototype.uninitialize.call(this);
  this.envSelect = null;
};


ViewerSettingsPanel.prototype.setVisible = function (show) {
  this.visible = show;
  SettingsPanel.prototype.setVisible.call(this, show);
  show && this.sizeToContent();

  if (show) {
    this.createEnvironmentPanel();
  }
};

/**
    * Creates a checkbox element and adds it to the given tab.
    *
    * @param {number} tabId - tab id
    * @param {string} description - the text associated with the checkbox
    * @param {boolean} initialState - initial value for the checkbox (checked or not)
    * @param {function} onchange - callback that is called when the checkbox is changed
    * @param {string} saveKey - name of the preference associated with this checkbox.
    * @returns {string} - it returns the checkbox element.
    *
    */
ViewerSettingsPanel.prototype.addCheckbox = function (tabId, name, description, initialState, onchange, saveKey)
{
  var viewer = this.viewer;

  // Use the stored settings or defaults
  var storedState = viewer.prefs[saveKey];
  initialState = typeof storedState === 'boolean' ? storedState : initialState;

  function onChangeCB(checked) {
    if (saveKey) {
      viewer.prefs.set(saveKey, checked);
    }
    onchange(checked);
  }

  var checkboxId = SettingsPanel.prototype.addCheckbox.call(this, tabId, name, initialState, onChangeCB, description);
  var checkBoxElem = this.getControl(checkboxId);
  checkBoxElem.saveKey = saveKey;

  if (saveKey) {
    viewer.prefs.addListeners(saveKey, function (value) {
      checkBoxElem.setValue(value);
    }, function (value) {
      checkBoxElem.setValue(value);
      onchange(value);
    });
  } else
  {
    checkBoxElem.sliderRow.classList.add('logical-group');
  }

  if (viewer.prefs.hasTag(saveKey, 'no-storage')) {
    checkBoxElem.sliderRow.classList.add('no-storage');
    this.modelPreferenceCount++;
  }
  return checkboxId;
};

/**
    * Creates a row and a slider element and adds it to the given tab.
    *
    * @param {number} tabId - tab id
    * @param {string} caption - the caption associated with the slider
    * @param {string} description - the text associated with the slider
    * @param {boolean} initialValue - initial value for the slider (checked or not)
    * @param {function} onchange - callback that is called when the slider is changed
    * @param {string} saveKey - name of the preference associated with this slider.
    * @returns {string[]} - it returns the row and slider control ids.
    */
ViewerSettingsPanel.prototype.addSliderV2 = function (tabId, caption, description, min, max, initialValue, onchange, options, saveKey)
{
  var viewer = this.viewer;

  // Use the stored settings or defaults
  var storedState = viewer.prefs[saveKey];
  initialValue = typeof storedState === 'number' ? storedState : initialValue;

  if (saveKey && !viewer.prefs.hasOwnProperty(saveKey)) {
    // Add the preferences.
    viewer.prefs.add(saveKey, initialValue, ['2d', '3d']);
  }

  function onChangeCB(event) {
    var value = typeof event === 'number' ? event : Number(event.detail.value);
    if (saveKey) {
      viewer.prefs.set(saveKey, value);
    }
    onchange(value);
  }

  var sliderId = SettingsPanel.prototype.addSliderV2.call(this, tabId, caption, description, min, max, initialValue, onChangeCB, options);
  var sliderElem = this.getControl(sliderId[1]);
  sliderElem.saveKey = saveKey;

  if (saveKey) {
    viewer.prefs.addListeners(saveKey, function (value) {
      sliderElem.setValue(value);
    }, function (value) {
      sliderElem.setValue(value);
      onchange(value);
    });
  } else
  {
    sliderElem.sliderRow.classList.add('logical-group');
  }

  if (viewer.prefs.hasTag(saveKey, 'no-storage')) {
    sliderElem.sliderRow.classList.add('no-storage');
    this.modelPreferenceCount++;
  }
  return sliderId;
};

/**
    * @private
    */
function generateEnvThumbnail(generator, image, preset) {
  generator.createThumbnail(preset).then(function (url) {
    image.src = url;
    image.onload = function () {
      URL.revokeObjectURL = url;
    };
  });
}

ViewerSettingsPanel.prototype.addGrid = function (caption, parentTable, items, initialItemIndex, onClick, saveKey) {

  var table = parentTable;

  var envContainer = document.createElement("div");
  envContainer.classList.add("environments-container");
  table.appendChild(envContainer);

  var envRow = document.createElement("div");
  envRow.classList.add("environments-lighting-table");
  envContainer.appendChild(envRow);

  var generator = new LightPresetThumbnails(42, 26);

  for (var i = 0; i < items.length; i++) {

    var preset = items[i];

    var cell = document.createElement("div");
    cell.classList.add("settings-environment-cell");
    cell.index = i;

    var image = document.createElement("img");
    image.classList.add("settings-environment-image");
    generateEnvThumbnail(generator, image, preset);

    cell.appendChild(image);

    var name = document.createElement("span");
    name.textContent = i18n.translate(preset.name);
    name.classList.add("settings-environment-name");
    name.setAttribute('data-i18n', preset.name);
    cell.appendChild(name);

    cell.addEventListener("click", function () {
      onClick(this.index);
    });

    envRow.appendChild(cell);
  }

  // Update environment selection
  var that = this;
  var viewer = this.viewer;
  viewer.prefs.addListeners(saveKey, function () {
    that.updateEnvironmentSelection();
  }, function (value) {
    onClick(viewer.prefs.lightPreset);
    that.updateEnvironmentSelection();
  });

  this.updateEnvironmentSelection();
  return envRow;
};

ViewerSettingsPanel.prototype.updateEnvironmentSelection = function () {
  if (!this.is3dMode) {
    return;
  }

  if (!this.envTabCreated)
  return;

  var index = this.viewer.prefs.lightPreset;
  var cells = this.gridTable.querySelectorAll(".settings-environment-cell");
  for (var j = 0; j < cells.length; j++) {
    if (cells[j].index === index) {
      cells[j].classList.add("border-select");
    } else {
      cells[j].classList.remove("border-select");
    }
  }
};

/**
    * Removes an option from the given tab.
    *
    * @param {HTMLElement} checkBoxElem - checkbox to remove.
    * @returns {boolean} - True if the checkbox was removed.
    */
ViewerSettingsPanel.prototype.removeCheckbox = function (checkBoxElem)
{
  this.viewer.prefs.removeListeners(checkBoxElem.saveKey);
  this.removeEventListener(checkBoxElem, "change", checkBoxElem.changeListener);

  return SettingsPanel.prototype.removeCheckbox.call(this, checkBoxElem);
};

/**
    *  Populates the navigation tab with the appropriate checkboxes.
    */
ViewerSettingsPanel.prototype.createNavigationPanel = function ()
{
  var viewer = this.viewer;
  var navTab = ViewerSettingTab.Navigation;
  var dolly = viewer.toolController.getTool('dolly');

  if (this.is3dMode) {

    this.addLabel(navTab, "ViewCube");

    this.addCheckbox(navTab, "Show ViewCube", "Toggles availability of the ViewCube navigation control", true, function (checked) {
      viewer.getExtension("Autodesk.ViewCubeUi", function (ext) {
        ext.displayViewCube(checked);
      });
    }, "viewCube");

    if (!isMobileDevice()) {
      this.addCheckbox(navTab, "ViewCube acts on pivot", "When enabled, the ViewCube orbits the view around the active pivot point When disabled, it orbits around the center of the view", false, function (checked) {
        viewer.setUsePivotAlways(checked);
      }, "alwaysUsePivot");
    }

    this.addLabel(navTab, "Orbit");

    this.addCheckbox(navTab, "Fusion style orbit", "Enables Fusion-style orbit overlay and gives the ability to lock orbit axis", false, function (checked) {
      if (checked)
      viewer.loadExtension('Autodesk.Viewing.FusionOrbit', null);else

      viewer.unloadExtension('Autodesk.Viewing.FusionOrbit', null);
    }, "fusionOrbit");

    this.addCheckbox(navTab, "Orbit past world poles", "Allows view rotation to continue past the model’s North Pole", true, function (checked) {
      viewer.setOrbitPastWorldPoles(checked);
    }, "orbitPastWorldPoles");

    this.addLabel(navTab, "Zoom");

    if (!isMobileDevice()) {
      this.addCheckbox(navTab, "Zoom towards pivot", "When disabled, zooming operations are centered at the current cursor location", false, function (checked) {
        viewer.setZoomTowardsPivot(checked);
      }, "zoomTowardsPivot");

      this.addCheckbox(navTab, "Reverse mouse zoom direction", "Toggles direction of zooming in and out", false, function (checked) {
        viewer.setReverseZoomDirection(checked);
      }, "reverseMouseZoomDir");

      this.scrollSpeed = initScrollSpeed.call(this);
    }

    this.dragSpeed = initDragSpeed.call(this);

    // This label should probably be called something else for mobile.
    this.addLabel(navTab, "Mouse");
    if (!isMobileDevice()) {
      this.addCheckbox(navTab, "Left handed mouse setup", "Swaps the buttons on the mouse", false, function (checked) {
        viewer.setUseLeftHandedInput(checked);
      }, "leftHandedMouseSetup");

      this.addCheckbox(navTab, "Set pivot with left mouse button", "Change left-click behavior to set new pivot point (overrides select object)", false, function (checked) {
        viewer.setClickToSetCOI(checked);
      }, "clickToSetCOI");
    }
    this.addCheckbox(navTab, "Open properties on select", "Always show properties upon selecting object", true, function (checked) {
      viewer.setPropertiesOnSelect(checked);
    }, "openPropertiesOnSelect");
  }

  if (!this.is3dMode) {

    this.addLabel(navTab, "Zoom");

    this.addCheckbox(navTab, "Reverse mouse zoom direction", "Toggles direction of zooming in and out", false, function (checked) {
      viewer.setReverseZoomDirection(checked);
    }, "reverseMouseZoomDir");

    if (!isMobileDevice()) {
      this.scrollSpeed = initScrollSpeed.call(this);
    }

    this.dragSpeed = initDragSpeed.call(this);

    this.addLabel(navTab, "Mouse");

    this.addCheckbox(navTab, "Open properties on select", "Always show properties upon selecting object", true, function (checked) {
      viewer.setPropertiesOnSelect(checked);
    }, "openPropertiesOnSelect");

    if (!isMobileDevice()) {
      this.addCheckbox(navTab, "Left handed mouse setup", "Swaps the buttons on the mouse", false, function (checked) {
        viewer.setUseLeftHandedInput(checked);
      }, "leftHandedMouseSetup");
    }

  }

  // Creates the drag speed slider
  function initDragSpeed() {
    return this.addSliderV2(navTab, 'Drag Speed', 'Changes sensitivity of mouse movement with the zoom tool', 5, 300, dolly.getDollyDragScale(),
    function (value) {
      dolly.setDollyDragScale(value);
    },
    { step: 5 }, 'zoomDragSpeed')[1];
  }

  // Creates the scroll speed slider
  function initScrollSpeed() {
    return this.addSliderV2(navTab, 'Scroll Speed', 'Changes sensitivity of the mouse scroll wheel when zooming', 0.1, 3.0, dolly.getDollyScrollScale(),
    function (value) {
      dolly.setDollyScrollScale(value);
    }, { step: 0.1 }, 'zoomScrollSpeed')[1];
  }
};

/**
    * Adds a button to the configuration tab. Invokes a callback when end-users click on the button.
    * @param {string} label - Button's user-facing text
    * @param {function} onClickCb - Callback that will be called when the tool is clicked.
    * @returns {string} An identifier required to remove the button from the panel.
    */
ViewerSettingsPanel.prototype.addConfigButton = function (label, onClickCb) {var _this = this;

  if (!onClickCb)
  throw new Error('Must register a function callback.');

  // Add the Tools label to the Configuration tab
  if (!this._externalButtonsLabel)
  this._externalButtonsLabel = this.addLabel(ViewerSettingTab.Configuration, 'More');

  // Add button
  var btnId = this.addButton(ViewerSettingTab.Configuration, label);
  var btn = this.getControl(btnId);
  btn.setOnClick(function () {
    _this.setVisible(false);
    onClickCb();
  });
  this._externalButtonIds.push(btnId);
  return btnId;
};

/**
    * Removes a config button from the Configuration tab.
    * @param {string} buttonId - Identifier obtained via {@link #addConfigButton}.
    * @returns {boolean} True if the button was removed.
    */
ViewerSettingsPanel.prototype.removeConfigButton = function (buttonId) {

  var index = this._externalButtonIds.indexOf(buttonId);
  if (index === -1)
  return false;

  var btn = this.getControl(buttonId); // btn should always be present at this stage.
  if (!btn)
  return false;

  this.removeControl(btn);
  this._externalButtonIds.splice(index, 1);

  // Remove label when no config buttons are present
  if (this._externalButtonIds.length === 0 && this._externalButtonsLabel !== null) {
    this._externalButtonsLabel.removeFromParent();
    this._externalButtonsLabel = null;
  }
  return true;
};

/** Populates the Configuration tab with the appropriate checkboxes.
    *
    */
ViewerSettingsPanel.prototype.createConfigurationPanel = function () {
  var viewer = this.viewer;
  var configTab = ViewerSettingTab.Configuration;
  var table = this.tablesContainer.childNodes[0];


  if (this.is3dMode) {

    this.addLabel(configTab, "Performance Optimization");

    this.optimizeNavigationhkBoxId = this.addCheckbox(configTab, "Smooth navigation", "Provides faster response(but degrades quality) while navigating",
    isMobileDevice(), function (checked) {
      viewer.setOptimizeNavigation(checked);
    }, "optimizeNavigation");

    this.progressiveRenderChkBoxId = this.addCheckbox(configTab, "Progressive display", "Shows incremental updates of the view and allows for more responsive interaction with the model (some elements may flicker) This improves perceived waiting time",
    true, function (checked) {
      viewer.setProgressiveRendering(checked);
    }, "progressiveRendering");

    this.addLabel(configTab, "Display");

    this.ghosthiddenChkBoxId = this.addCheckbox(configTab, "Ghost hidden objects", "Leave hidden objects slightly visible",
    true, function (checked) {
      viewer.setGhosting(checked);
    }, "ghosting");

    this.displayLinesId = this.addCheckbox(configTab, "Display Lines", "Toggles display of line objects", true, function (checked) {
      viewer.hideLines(!checked);
    }, "lineRendering");


    this.displayPointsId = this.addCheckbox(configTab, "Display Points", "Toggles display of point objects", true, function (checked) {
      viewer.hidePoints(!checked);
    }, "pointRendering");


    this.displayEdgesId = this.addCheckbox(configTab, "Display edges", "Shows outline of model surfaces", false, function (checked) {
      viewer.setDisplayEdges(checked);
    }, "edgeRendering");

  }
  if (!this.is3dMode) {
    // 2D only

    this.addLabel(configTab, "Performance Optimization");

    this.progressiveRenderChkBoxId = this.addCheckbox(configTab, "Progressive display", "Shows incremental updates of the view and allows for more responsive interaction with the model (some elements may flicker) This improves perceived waiting time",
    true, function (checked) {
      viewer.setProgressiveRendering(checked);
    }, "progressiveRendering");

  }
};
/**
    * Populates the appearance tab with the appropriate checkboxes.
    */

ViewerSettingsPanel.prototype.createAppearancePanel = function () {
  var viewer = this.viewer;
  var appearTab = ViewerSettingTab.Appearance;
  var table = this.tablesContainer.childNodes[2];


  if (this.is3dMode) {

    this.addLabel(appearTab, "Visual Quality Optimization");

    this.antialiasingChkBoxId = this.addCheckbox(appearTab, "Anti-aliasing", "Remove jagged edges from lines", true, function (checked) {
      viewer.setQualityLevel(viewer.prefs.ambientShadows, checked);
    }, "antialiasing");

    this.ambientshadowsChkBoxId = this.addCheckbox(appearTab, "Ambient shadows", "Improve shading of occluded surfaces", true, function (checked) {
      viewer.setQualityLevel(checked, viewer.prefs.antialiasing);
    }, "ambientShadows");

    this.groundShadowChkBoxId = this.addCheckbox(appearTab, "Ground shadow", "Add simulated ground surface shadows", true, function (checked) {
      viewer.setGroundShadow(checked);
    }, "groundShadow");

    this.groundReflectionChkBoxId = this.addCheckbox(appearTab, "Ground reflection", "Add simulated ground surface reflections", false, function (checked) {
      viewer.setGroundReflection(checked);
    }, "groundReflection");
  }

  if (!this.is3dMode) {

    this.addLabel(appearTab, "Existing behavior");

    this.swapBlackAndWhiteChkBoxId = this.addCheckbox(appearTab, "2D Sheet Color", "Switch sheet color white to black", true, function (checked) {
      viewer.setSwapBlackAndWhite(checked);
    }, "swapBlackAndWhite");
  }
};


ViewerSettingsPanel.prototype.createEnvironmentPanel = function () {
  if (!this.is3dMode) {
    return;
  }

  if (this.envTabCreated)
  return;

  this.envTabCreated = true;

  var viewer = this.viewer;
  var environmentTab = ViewerSettingTab.Environment;
  var table = this.tablesContainer.childNodes[3];
  this.gridTable = table;

  this.addLabel(environmentTab, "Environment");

  this.envMapBackgroundChkBoxId = this.addCheckbox(environmentTab, "Environment Image Visible", "Shows lighting environment as background", true, function (checked) {
    viewer.setEnvMapBackground(checked);
  }.bind(this), "envMapBackground");

  var captionRow = table.tBodies[0].insertRow(-1);

  var cell = captionRow.insertCell(0);
  this.caption = document.createElement("div");
  this.caption.setAttribute("data-i18n", "Environments and Lighting Selection");
  this.caption.textContent = i18n.translate("Environments and Lighting Selection");
  this.caption.classList.add("settings-row-title");
  cell.appendChild(this.caption);
  cell.colSpan = "3";

  this.envSelect = this.addGrid(
  "Environments and Lighting Selection",
  table,
  LightPresets,
  viewer.impl.currentLightPreset(),
  function onAction(index) {
    viewer.setLightPreset(index);
  },
  "lightPreset");


  // Only display the icons with environment.
  this.envSelect.classList.add("with-environment");
};

/**
    * Adds viewer version label to Footer div.
    */
ViewerSettingsPanel.prototype.createVersionLabel = function (parent) {

  if (!parent)
  return;
  this.versionDiv = document.createElement('div');
  this.versionDiv.textContent = getVersionString(); // No need to localize.
  this.versionDiv.className = 'docking-panel-version-label';
  parent.appendChild(this.versionDiv);
};

/**
    * Create a restore default settings button. It is appended to the settings panel
    */
ViewerSettingsPanel.prototype.createRestoreDefaultSettingsButton = function () {
  var viewer = this.viewer;

  this.restoreDiv = document.createElement('div');
  this.restoreDiv.classList.add('docking-panel-container-solid-color-b');
  this.restoreDiv.classList.add('restore-defaults-container');

  this.restoreButton = document.createElement('div');
  this.restoreButton.className = 'docking-panel-tertiary-button';
  this.restoreButton.setAttribute("data-i18n", "Restore all default settings");
  this.restoreButton.textContent = Autodesk.Viewing.i18n.translate("Restore all default settings");
  this.restoreDiv.appendChild(this.restoreButton);

  this.addEventListener(this.restoreDiv, 'touchstart', touchStartToClick);
  this.addEventListener(this.restoreDiv, 'click', function () {
    viewer.restoreDefaultSettings();
  }, false);

  this.scrollContainer.appendChild(this.restoreDiv);
};


ViewerSettingsPanel.prototype.selectTab = function (tabId) {
  SettingsPanel.prototype.selectTab.call(this, tabId);
  this.sizeToContent();
};

/**
    * Resizes panel vertically to wrap around the content.
    * It will always leave some room at the bottom to display the toolbar.
    */
ViewerSettingsPanel.prototype.sizeToContent = function () {
  SettingsPanel.prototype.sizeToContent.call(this, this.viewer.container);
};

ViewerSettingsPanel.prototype.onViewerResize = function (vt, vb, vl, vr, vw, vh) {
  // Avoid default behavior by overriding inherited implementation.
  this.sizeToContent();
};

/**
    * Updates the values in the checkboxes based on what is in the prefs.
    */
ViewerSettingsPanel.prototype.syncUI = function () {
  var viewer = this.viewer;
  var prefs = this.viewer.prefs;

  this.setControlValue(this.antialiasingChkBoxId, prefs.antialiasing);
  this.setControlValue(this.ambientshadowsChkBoxId, prefs.ambientShadows);
  this.setControlValue(this.groundShadowChkBoxId, prefs.groundShadow);
  this.setControlValue(this.groundReflectionChkBoxId, prefs.groundReflection);
  this.setControlValue(this.envMapBackgroundChkBoxId, viewer.impl.isEnvMapBackground());
  this.setControlValue(this.progressiveRenderChkBoxId, prefs.progressiveRendering);
  this.setControlValue(this.swapBlackAndWhiteChkBoxId, prefs.swapBlackAndWhite);
  this.setControlValue(this.ghosthiddenChkBoxId, prefs.ghosting);
  this.setControlValue(this.displayLinesId, prefs.lineRendering);
  this.setControlValue(this.displayPointsId, prefs.pointRendering);
  this.setControlValue(this.displayEdgesId, prefs.edgeRendering);
  this.setControlValue(this.scrollSpeed, prefs.zoomScrollSpeed);
  this.setControlValue(this.dragSpeed, prefs.zoomDragSpeed);

  this.updateEnvironmentSelection();
};

/**
    * Safely sets the value of a checkbox control.
    * 
    * @param {string} ctrlName - The id of the control
    * @param {boolean} value
    */
ViewerSettingsPanel.prototype.setControlValue = function (ctrlName, value) {
  var ctrl = this.getControl(ctrlName);
  if (ctrl) {
    ctrl.setValue(value);
  }
};


function getVersionString() {
  var version = LMV_VIEWER_VERSION;
  if (LMV_VIEWER_VERSION.charAt(0) === "@") {
    version = '0.0.0'; // No need to localize.
  }
  return 'v' + version;
}