import { ViewingService } from "../net/Xhr";
import { isChrome, isWindows, isMobileDevice, isNodeJS, isIOSDevice } from "../../compat";
import { pathToURL } from "../net/Xhr";
import { errorCodeString, ErrorCodes } from "../net/ErrorCodes";
import { MaterialConverter } from "../../wgs/render/MaterialConverter";
import { DecodeEnvMap } from "../../wgs/render/DecodeEnvMap";
import { logger } from "../../logger/Logger";
import * as THREE from "three";
import { DDSLoader } from "../../../thirdparty/three.js/DDSLoader";
import { endpoint } from "../net/endpoints";
var Pend = require("pend"); //this module has issues with ES6 import because it sets module.exports directly.


//Texture parallel request rate limiting
var _texQueue = new Pend();
_texQueue.max = isMobileDevice() ? 4 : 6;

var _requestsInProgress = 0;
var TEXTURE_MEMORY = isMobileDevice() ? 32 : Infinity;
TEXTURE_MEMORY *= 1024 * 1024;
var _textureCount = 0;
var _textureSize = Infinity; // Max texture sizes in pixels

function resizeImage(img) {

  var ow = img.width;
  var oh = img.height;
  var w, h;

  //It's a power of two already and not too large
  if ((ow & ow - 1) === 0 && (oh & oh - 1) === 0) {
    if (ow * oh <= _textureSize) {
      return img;
    }
    w = ow;
    h = oh;
  } else {
    w = 1;while (w * 1.5 < ow) {w *= 2;}
    h = 1;while (h * 1.5 < oh) {h *= 2;}
  }

  while (w * h > _textureSize) {
    w = Math.max(w / 2, 1);
    h = Math.max(h / 2, 1);
  }

  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  canvas.width = w;
  canvas.height = h;

  // if a resize happens, set this special flag to note it.
  canvas.wasNPOT = true;

  ctx.drawImage(img, 0, 0, w, h);

  return canvas;

}

function imageToCanvas(img) {

  var w = img.width;
  var h = img.height;

  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  ctx.globalCompositeOperation = "copy";
  canvas.width = w;
  canvas.height = h;

  ctx.drawImage(img, 0, 0, w, h);

  return canvas;

}


function textureHasAlphaChannel(texture) {

  return texture.format === THREE.AlphaFormat || texture.format === THREE.RGBAFormat;

}

function textureUsesClamping(texture) {

  return texture.clampS || texture.clampT;

}

function textureUsesMipmapping(texture) {

  return texture.minFilter !== THREE.NearestFilter && texture.minFilter !== THREE.LinearFilter;

  // Full test, but the Chrome bug happens only on mipmapping, from what we can tell.
  // if wrapping is not clamp to edge, or minFilter is a mipmap mode, then we need power of two.
  //return ( texture.wrapS !== THREE.ClampToEdgeWrapping || texture.wrapT !== THREE.ClampToEdgeWrapping ) ||
  //  ( texture.minFilter !== THREE.NearestFilter && texture.minFilter !== THREE.LinearFilter );

}


function applyBrowserSpecificSizeHacks(tex) {
  // check: if the texture is not a power-of-two, then turn off mipmapping

  // At this point all textures are powers of two. However, Chrome cannot use mipmapping if
  // this image was a non-power-of-two with an alpha channel. This is a bug in Chrome.
  // If wasNPOT is true, then we need it off, but only if the filter is clamped, for some reason.
  // See https://jira.autodesk.com/browse/LMV-2556 and linked defects.
  // This entire test and corrective action can be removed once the version of Chrome is past
  // Version 60.0.3086.0, see https://jira.autodesk.com/browse/LMV-2426. If we remove this
  // patch, please also remove wasNPOT getting set in resizeImage().
  if (tex.image.wasNPOT === true &&
  textureHasAlphaChannel(tex) &&
  textureUsesClamping(tex) &&
  textureUsesMipmapping(tex) &&
  // This fix can be removed as soon as Windows Chrome build is Version 60.0.3086.0 (Official Build) canary (64-bit)
  isChrome() &&
  isWindows())
  {

    // turn mipmapping off - TODO need to check for PNG alpha
    tex.minFilter = THREE.LinearFilter;
    tex.generateMipmaps = false;

    tex.needsUpdate = true;
  }
}

function arrayBufferToImageUrl(buffer) {

  var arrayBuffer = new Uint8Array(buffer);
  var blob = new Blob([arrayBuffer], { type: "image/jpeg" });
  var urlCreator = window.URL || window.webkitURL;

  return urlCreator.createObjectURL(blob);
}


function loadTextureWithSecurity(path, isCDN, mapping, callback, onError, acmSessionId, skipResize, options) {

  var useCredentials = !isCDN && endpoint.getUseCredentials() || endpoint.getCdnUsesCredentials();

  //Set up CORS for the image element
  if (useCredentials) {//CORS with credentials
    THREE.ImageUtils.crossOrigin = 'use-credentials';
  } else if (endpoint.getUseCredentials()) {//CORS without credentials (yes, the API is confusingly named, it should be "getUseCORS" perhaps?)
    THREE.ImageUtils.crossOrigin = 'anonymous';
  } else {
    THREE.ImageUtils.crossOrigin = ''; //No CORS.
  }

  var queryParams = "";
  if (useCredentials && acmSessionId) {
    queryParams = "acmsession=" + acmSessionId;
  }

  if (options && options.queryParams) {
    queryParams = queryParams ? queryParams + "&" : "";
    queryParams += options.queryParams;
  }

  var loadContext = endpoint.initLoadContext({ queryParams: queryParams });

  _requestsInProgress++;

  _texQueue.go(function (pendCB) {

    var callbackWithoutResize = function callbackWithoutResize(tex, error) {
      _requestsInProgress--;
      if (error && onError) {
        onError(error);
      } else {
        callback(tex);
      }
      pendCB();
    };

    //In the web browser (non-node) case, we always pass through
    //the power of two resizer if the image is not opaque DataTexture
    var callbackWithResize = skipResize ? callbackWithoutResize :
    function (tex) {
      _requestsInProgress--;
      if (tex && tex.image) {
        tex.image = resizeImage(tex.image);
      }
      callback(tex);
      pendCB();
    };

    var simpleError = function simpleError(e) {
      _requestsInProgress--;
      logger.error("Texture load error", e);
      callback(null);
      pendCB();
    };

    //For node.js, always use the "manual" load code path
    if (isNodeJS()) {
      loadTextureWithTokenNode(path, loadContext, mapping, callbackWithoutResize, options);
      return;
    }

    if (path.slice(path.length - 4).toLocaleLowerCase() === ".dds") {
      if (isIOSDevice()) {
        var pvrPath = path.slice(0, path.length - 4) + ".pvr";
        new PVRLoader().load(pvrPath + "?" + loadContext.queryParams, callbackWithoutResize, simpleError);
      } else {
        new DDSLoader().load(path + "?" + loadContext.queryParams, callbackWithoutResize, simpleError);
      }
    } else if (useCredentials && !isCDN || options && (options.rawData || options.extractImage)) {
      loadTextureWithToken(path, loadContext, mapping, callbackWithResize, options);
    } else {
      THREE.ImageUtils.loadTexture(loadContext.queryParams ? path + "?" + loadContext.queryParams : path, mapping, callbackWithResize, simpleError);
    }
  });

}



// For texture loading, three.js expects loadable URL for the image.
// When we put the token in request header instead of cookie, we need AJAX the
// texture and base64 encode it to create a data URI and feed it to three.js.
function loadTextureWithToken(path, loadContext, mapping, callback, options) {

  var texture = new THREE.Texture(undefined, mapping);

  function onSuccess(data) {
    if (options && options.extractImage) {
      data = options.extractImage(data);
    }

    var image = new Image();
    texture.image = image;

    applyBrowserSpecificSizeHacks(texture);

    image.onload = function () {
      texture.needsUpdate = true;
      if (callback) callback(texture);

      window.URL.revokeObjectURL(image.src);
    };
    image.onerror = function (e) {
      logger.error(e, errorCodeString(ErrorCodes.UNKNOWN_FAILURE));
      if (callback) callback(null);
    };

    image.src = arrayBufferToImageUrl(data);
  }

  function onTextureFailure(statusCode, statusText) {

    var errorMsg = "Error: " + statusCode + " (" + statusText + ")";
    logger.error(errorMsg, errorCodeString(ErrorCodes.NETWORK_SERVER_ERROR));

    //We need to call the callback because it decrements the pending texture counter
    callback && callback(null, { msg: statusText, args: statusCode });
  }

  if (options && options.rawData) {
    onSuccess(options.rawData);
  } else {
    ViewingService.getItem(loadContext, path, onSuccess, onTextureFailure);
  }

  return texture;
}


function loadTextureWithTokenNode(path, loadContext, mapping, callback, options) {

  var texture = new THREE.DataTexture(undefined, mapping);

  function onSuccess(data) {
    if (options && options.extractImage) {
      data = options.extractImage(data);
    }

    texture.image = { data: data, width: undefined, height: undefined };

    texture.needsUpdate = true;
    if (callback) callback(texture);
  }

  function onTextureFailure(statusCode, statusText) {

    var errorMsg = "Error: " + statusCode + " (" + statusText + ")";
    logger.error(errorMsg, errorCodeString(ErrorCodes.NETWORK_SERVER_ERROR));

    //We need to call the callback because it decrements the pending texture counter
    callback && callback(null, { msg: statusText, args: statusCode });
  }

  ViewingService.getItem(loadContext, path, onSuccess, onTextureFailure);

  return texture;

}


function requestTexture(uri, model, onReady) {

  var svf = model.getData();

  function determineSvfTexturePath(uri) {

    var texPath = null;

    for (var j = 0; j < svf.manifest.assets.length; ++j)
    {
      var asset = svf.manifest.assets[j];
      if (asset.id.toLowerCase() == uri.toLowerCase()) {
        texPath = pathToURL(svf.basePath + asset.URI);
        break;
      }
    }
    if (!texPath) {
      texPath = pathToURL(svf.basePath + uri);
    }

    return { path: texPath, isShared: false };
  }

  function determineOtgTexturePath(uri) {

    var loadContext = endpoint.initLoadContext({});

    // get request url
    var url = svf.makeSharedResourcePath(loadContext.otg_cdn, "textures", uri);

    return { path: url, isShared: !!loadContext.otg_cdn };
  }

  var texPathData = model.isOTG() ? determineOtgTexturePath(uri) : determineSvfTexturePath(uri);

  return loadTextureWithSecurity(texPathData.path, texPathData.isShared, THREE.UVMapping, onReady, null, svf.acmSessionId);
}


function loadMaterialTextures(model, material, viewerImpl) {

  if (!material.textureMaps)
  return;

  if (material.texturesLoaded)
  return;

  material.texturesLoaded = true;

  var svf = model.getData();

  // Iterate and parse textures from ugly JSON for each texture type in material.
  // If has URI and valid mapName load and initialize that texture.
  var textures = material.textureMaps;
  for (var mapName in textures) {
    var textureDef = textures[mapName];

    if (!viewerImpl.matman().loadTextureFromCache(model, material, textureDef, mapName)) {

      //Create the three.js texture object (with delay loaded image data)
      var texture = requestTexture(textureDef.uri, model,
      //capture map because it varies inside the loop
      function (textureDef) {
        return function (tex) {

          //NOTE: tex could be null here in case of load error.
          if (tex) {
            var units = svf.materials.scene.SceneUnit;
            var anisotropy = viewerImpl.renderer() ? viewerImpl.renderer().getMaxAnisotropy() : 0;
            MaterialConverter.convertTexture(textureDef, tex, units, anisotropy);
          }

          var matman = viewerImpl.matman();

          //It's possible MaterialManager got destroyed before the texture loads
          if (!matman)
          return;

          matman.setTextureInCache(model, textureDef, tex);

          //Private API: Call a custom texture processing callback if one is supplied.
          //This is used for texture processing in node.js tools.
          //We are avoiding a more generic fireEvent mechanism in order to avoid publishing
          //yet another event type.
          if (svf.loadOptions.onTextureReceived) {
            svf.loadOptions.onTextureReceived(matman, textureDef, tex, !requestsInProgress());
          }

          //Unfortunately we have to check for texture load complete here also, not just
          //in the final call to loadTextures. This is because geometry load can complete
          //before or after texture load completes.
          if (!requestsInProgress() && viewerImpl && svf.loadDone && !svf.texLoadDone) {
            svf.texLoadDone = true;
            viewerImpl.onTextureLoadComplete(model);
          }
        };
      }(textureDef));

    }
  }

}


/**
   * Loads all textures for a specific model.
   * Textures delayed until all geometry is loaded, hence not done in convertMaterials.
   */
function loadModelTextures(model, viewerImpl) {

  var matman = viewerImpl.matman();

  var hash = matman._getModelHash(model);


  //Set textureCount to enable texture resizing on mobile.
  //This is only really useful to determine texture budget when a single SVF
  //is to be loaded. It doesn't work at all if multiple models are to be loaded/unloaded
  //and the OTG loader doesn't ever pass through here, because it loads materials one by one.
  var textureCount = 0;

  if (model.isOTG()) {
    textureCount = model.getData().metadata.stats.num_textures || 0;
  } else {
    for (var p in matman._materials) {

      //Prevent textures for already loaded models from being loaded
      //again. Not elegant, and we can somehow only process the materials
      //per model.
      if (p.indexOf(hash) === -1)
      continue;

      var material = matman._materials[p];
      if (material.textureMaps) {
        textureCount += Object.keys(material.textureMaps).length;
      }
    }
  }

  setTextureCount(textureCount);


  for (var p in matman._materials) {

    //Prevent textures for already loaded models from being loaded
    //again. Not elegant, and we can somehow only process the materials
    //per model.
    if (p.indexOf(hash) === -1)
    continue;

    var material = matman._materials[p];
    loadMaterialTextures(model, material, viewerImpl);
  }


  //Model had no textures at all, call the completion callback immediately
  var svf = model.getData();
  if (!requestsInProgress() && viewerImpl && svf.loadDone && !svf.texLoadDone) {
    svf.texLoadDone = true;
    viewerImpl.onTextureLoadComplete(model);
  }
}


function loadCubeMap(path, exposure, onReady) {

  var texLoadDone = function texLoadDone(map) {

    if (map) {
      map.mapping = THREE.CubeReflectionMapping;
      map.LogLuv = path.indexOf("logluv") !== -1;
      map.RGBM = path.indexOf("rgbm") !== -1;

      // TODO: Turn on use of half-float textures for envmaps. Disable due to blackness on Safari.
      DecodeEnvMap(map, exposure, false /*isMobileDevice() ? false : this.viewer.glrenderer().supportsHalfFloatTextures()*/, onReady);
    } else {
      if (onReady) {
        onReady(map);
      }
    }

  };

  var cubeMap;

  THREE.ImageUtils.crossOrigin = '';

  if (Array.isArray(path)) {
    cubeMap = THREE.ImageUtils.loadTextureCube(path, THREE.CubeReflectionMapping, texLoadDone);
    cubeMap.format = THREE.RGBFormat;
  } else
  if (typeof path === "string") {
    if (path.toLowerCase().indexOf(".dds") !== -1) {
      cubeMap = new DDSLoader().load(path, texLoadDone);
    } else
    {
      cubeMap = THREE.ImageUtils.loadTexture(path, THREE.SphericalReflectionMapping, onReady);
      cubeMap.format = THREE.RGBFormat;
    }
  } else if (path) {
    //here we assume path is already a texture object
    if (onReady) {
      onReady(path);
    }
  } else
  {
    if (onReady) {
      onReady(null);
    }
  }

  return cubeMap;
}


/**
   * Return the number of outstanding texture requests
   */
function requestsInProgress() {
  return _requestsInProgress;
}

/**
   * Set the max request count
   * @param count The maximum number of outstanding request that can be started in parallel.
   */
function setMaxRequest(count) {
  if (count > 0)
  _texQueue.max = count;
}

/**
   * Get the max request count
   */
function getMaxRequest() {
  return _texQueue.max;
}

/**
   * Set the texture memory limit
   * @param size The memory allowed for textures.
   */
function setMemoryLimit(size) {
  if (size > 0) {
    TEXTURE_MEMORY = size;
    setTextureCount(_textureCount);
  }
}

/**
   * Get the texture memory limit
   */
function getMemoryLimit() {
  return TEXTURE_MEMORY;
}

/**
   * Set the texture count. This is set by loadModelTextures
   * @param count The count of textures for model
   */
function setTextureCount(count) {
  if (count >= 0) {
    _textureCount = count;
    _textureSize = Math.max(16 * 1024, TEXTURE_MEMORY / (_textureCount * 4));
  }
}

/**
   * Get the texture count
   */
function getTextureCount() {
  return _textureCount;
}

// Calculate the memory used by a texture
function calculateTextureSize(tex) {
  var pixsize = 4; // assume 4 byte pixels.
  switch (tex.format) {
    case THREE.AlphaFormat:
      pixsize = 1;
      break;
    case THREE.RGBFormat:
      pixsize = 3;
      break;
    case THREE.LuminanceFormat:
      pixsize = 1;
      break;
    case THREE.LuminanceAlphaFormat:
      pixsize = 2;
      break;}

  switch (tex.type) {
    case THREE.ShortType:
    case THREE.UnsignedShortType:
    case THREE.HalfFloatType:
      pixsize *= 2;
      break;
    case THREE.IntType:
    case THREE.UnsignedIntType:
    case THREE.FloatType:
      pixsize *= 4;
      break;
    case THREE.UnsignedShort4444Type:
    case THREE.UnsignedShort5551Type:
    case THREE.UnsignedShort565Type:
      pixsize = 2;
      break;}

  var rowsize = pixsize * tex.image.width;
  rowsize += tex.unpackAlignment - 1;
  rowsize -= rowsize % tex.unpackAlignment;
  return tex.image.height * rowsize;
}



export var TextureLoader = {
  loadTextureWithSecurity: loadTextureWithSecurity,
  loadMaterialTextures: loadMaterialTextures,
  loadModelTextures: loadModelTextures,
  loadCubeMap: loadCubeMap,
  requestsInProgress: requestsInProgress,
  calculateTextureSize: calculateTextureSize,
  imageToCanvas: imageToCanvas };