import { memoryOptimizedLoading } from '../globals';
import * as THREE from "three";
import { MeshFlags } from "./MeshFlags";
import { logger } from "../../logger/Logger";
import { allocateUintArray, reallocateUintArrayMaybe } from "./IntArray";
import { Viewer3D } from '../../application/Viewer3D';


var _tmpMatrix = new THREE.Matrix4();
var _tmpBox = new THREE.Box3();
var _tmpRot = new THREE.Quaternion();
var _tmpPos = new THREE.Vector3();
var _tmpScale = new THREE.Vector3();

/**
                                      * Represents the full list of all geometry instances associated with
                                      * a particular model. The order in the list is 1:1 with fragment list
                                      * in the source LMV/SVF package file.
                                      * @param {Object} fragments - Fragment data parsed from an SVF file.
                                      * @param {GeometryList} geoms - Geometry data parsed from an SVF file.
                                      * @constructor
                                      */
export function FragmentList(model) {

  this.is2d = model.is2d();
  this.modelId = model.getModelId();
  this.fragments = model.getData().fragments;
  this.geoms = model.getGeometryList();

  //3D SVF files are of known initial size and known world bounds.
  //2D F2D files start out with nothing and get filled up as we load.
  //NOTE: There is a bug here when we have an SVF file with known zero fragments -- it will
  //go into the slower non-fixed size code path. But it doesn't matter, because it's an empty file.
  this.isFixedSize = this.fragments.length > 0;
  if (this.isFixedSize) {
    this.boxes = this.fragments.boxes; // Float32Array, stores Boxes as 6 floats per fragment (after applying mesh matrix)
    this.transforms = this.fragments.transforms; // Float32Array, stores transforms as 12 floats per fragment (Matrix4 with omitted last row)
    this.useThreeMesh = !memoryOptimizedLoading;
  } else {
    this.boxes = null;
    this.transforms = null;
    this.useThreeMesh = true; //This code path will be used for 2D drawings, which stream in with unknown number of meshes
  }

  // initial length for arrays of meshes/geometries/flags
  // Can be zero.
  var initialSize = this.fragments.length;

  this.vizflags = new Uint16Array(initialSize); // visibility/highlight mode flags

  //This will be the list of all mesh instances in the model.
  //Corresponds to all fragments in the case of SVF.
  if (this.useThreeMesh)
  this.vizmeshes = new Array(initialSize);

  if (model.isOTG()) {
    var stats = model.getData().metadata.stats;
    var nm = stats.num_materials;
    var ng = stats.num_geoms;

    this.materialids = allocateUintArray(initialSize, nm);
    this.geomids = allocateUintArray(initialSize, ng);
  } else {
    this.geomids = new Int32Array(initialSize); // geomid per fragId. geomids are resolved by this.geoms.GetGeometry(geomid) to obtain BufferGeometry.
    this.materialids = new Int32Array(initialSize); // material per fragId. matIds  are resolved by this.materialmap[matId]
  }
  this.materialmap = {}; // map from material ids to THREE.ShaderMaterial instances
  this.materialIdMap = {};
  this.nextMaterialId = 1;

  // theming (coloring based on id)
  this.db2ThemingColor = []; // empty if no theming is applied. A theming color db2ThemingColor[dbId] is stored as THREE.Vector4 with values in [0,1].
  this.originalColors = []; // if vizmesh[i] has modified vertex-colors  due to theming,  originalColors[i]  stores a copy of the original colors.
  this.themingOrGhostingNeedsUpdate = []; // indicates if vertex-colors of vizmesh[i] needs to be updated based on recent theming or ghosting changes.
  this.dbIdIsHidden = []; // ids that we hide by setting alpha to 0

  // ghosting for 2d objects: A ghosted object is reduced in transparency and blended with the pageColor. This
  this.dbIdIsGhosted = [];

  this.animxforms = null; // If animation is used, this is a Float32Array storing 10 floats per fragment to describe scale (3), rotation (4), and translation (3).
  // See this.updateAnimTransform.

  for (var i = 0; i < initialSize; i++) {
    this.vizflags[i] = 1; //MESH_VISIBLE initially
  }

  // Set the visflags from the fragment visibility, if there is any.
  // For OTG, the flags are not fully loaded at this point. Otg flags are handled in setMesh().
  if (!model.isOTG() && this.fragments.visibilityFlags) {
    this.vizflags.set(this.fragments.visibilityFlags);
  }

  this.allVisible = true; // see this.areAllVisible(..). Indicates if MESH_VISIBLE flag is set for all meshes (i.e., not considering culling)
  this.allVisibleDirty = true; // if true, this.allVisible is outdated and must be recomputed in this.areAllVisible.
  this.nextAvailableFragID = initialSize;

  // Visibility of lines and points is independent of per-fragment visibility flags
  this.linesHidden = false;
  this.pointsHidden = false;

  // TODO: comment out; for debug
  //this.geomCount = 0;
  //this.geomMax = 100;
}

// [HB:] This method is only used in RenderModel.setFragment(), which seems to be not called at all. Can we remove this?
//       (including the nextAvailableFragID member and RenderModel.setFragment).
FragmentList.prototype.getNextAvailableFragmentId = function () {
  return this.nextAvailableFragID++;
};

// [HB:] When does this method ever return true? vizflags is resized in SetMesh, which only used in RenderModel.activateFragment and 
//       RenderModel.setFragment (never called). RenderModel.activateFragment(..) is only used by loaders when new fragments have been loaded.
//       However, for SvfLoader, fragments.length is always the full fragments count and for F2D, new stuff is first added to fragments, then
//       to VisFlags. 
//       Maybe this should actually be a "<" and is only relevant for F2D case?
FragmentList.prototype.fragmentsHaveBeenAdded = function () {
  return this.vizflags.length > this.fragments.length;
};

// Returns undefined if fragId has no material 
FragmentList.prototype.getSvfMaterialId = function (fragId) {
  var mat = this.getMaterial(fragId);
  return mat ? mat.svfMatId : undefined;
};

/**
    * Set mesh for a fragment, replacing any temporary previous one.
    * @param {number} fragId - Fragment ID
    * @param {Object} meshinfo - Object as defined in Viewer3DImpl.setupMesh(..). Contains:
    *      geometry: instanceof BufferGeometry
    *      material: instance of THREE.Material
    *      matrix:   instanceof THREE.Matrix4
    *      isLine:   bool to mark line geometry
    *      isWideLine: bool to mark wide line geometry
    *      isPoint:   bool to mark point geometry
    *      is2D:     bool to indicate 2D geometry (e.g., set by F2DLoader) 
    * @param {bool} updateFragmentData - If true, this.bbox and this.transforms is also updated for this fragment.
    *      Only allowed if this.isFixedSize==true. (otherwise, this.boxes and this.transforms is null)
    */
FragmentList.prototype.setMesh = function (fragId, meshInfo, updateFragmentData) {

  //Remove any temporary geometry we used for the fragment
  //while it was loading
  if (this.vizmeshes) {
    var oldGeom = this.vizmeshes[fragId];
    if (oldGeom && oldGeom.parent) {
      oldGeom.parent.remove(oldGeom);
    }
  }

  //The various data arrays need to be re-sized if the fragment is new
  //so we have to do it manually in case this happens. 
  if (this.vizflags.length <= fragId) {

    // Gradually should only used if isFixedSize is false (as used for F2D geometry)
    if (this.isFixedSize) {
      logger.warn("Attempting to resize a fragments list that was initialized with fixed data. This will have a performance impact.");
      this.isFixedSize = false;
    }

    // determine new length of all per-fragmentId arrays
    var nlen = Math.ceil(1.5 * this.vizflags.length) || 1;
    if (this.useThreeMesh && nlen < this.vizmeshes.length)
    nlen = this.vizmeshes.length;

    // re-allocate vizflags
    var nflags = new Uint16Array(nlen);
    nflags.set(this.vizflags);
    this.vizflags = nflags;

    // re-allocate other per-fragmentId arrays...

    if (this.transforms) {
      var ntransforms = new Float32Array(nlen * 12);
      ntransforms.set(this.transforms);
      this.transforms = ntransforms;
    }

    if (this.boxes) {
      var nboxes = new Float32Array(nlen * 6);
      nboxes.set(this.boxes);
      this.boxes = nboxes;
    }

    if (this.geomids) {
      var nids = new Int32Array(nlen);
      nids.set(this.geomids);
      this.geomids = nids;

    }

    if (this.materialids) {
      var nmids = new Int32Array(nlen);
      nmids.set(this.materialids);
      this.materialids = nmids;
    }
  }

  //Remember the mesh in the frag->viz mesh array
  if (this.useThreeMesh) {
    var mesh = new THREE.Mesh(meshInfo.geometry, meshInfo.material);

    // Copy matrix to mesh.matrix and mesh.matrixWorld
    // [HB:] Why copying twice?
    if (meshInfo.matrix) {
      if (mesh.matrix) {
        mesh.matrix.copy(meshInfo.matrix);
      }
      mesh.matrixWorld.copy(meshInfo.matrix);
    }

    mesh.is2d = meshInfo.is2d;
    mesh.isLine = meshInfo.isLine;
    mesh.isWideLine = meshInfo.isWideLine;
    mesh.isPoint = meshInfo.isPoint;

    // If we would leave that true, THREE.js would call UpdateMatrix() for this mesh and 
    // overwrite the matrix with another one computed by position, scale, and quaternion.
    mesh.matrixAutoUpdate = false;

    //Add the mesh to the render group for this fragment
    //Note each render group renders potentially many fragments.
    mesh.frustumCulled = false; //we do our own culling in RenderQueue, the renderer doesn't need to

    // keep fragId and dbId
    mesh.fragId = fragId;
    mesh.dbId = this.fragments.fragId2dbId[fragId] | 0;
    mesh.modelId = this.modelId;

    // cache the mesh in this.vizmeshes
    this.vizmeshes[fragId] = mesh;

  } else {
    // When not using THREE.Mesh, store ids of BufferGeometry and material instead

    // Handle shared Otg geoms: If the geometry contains a hash, it is a shareable Otg geometry. For these,
    // we cannot use svfid, because the geomId may vary per model.
    //  => For this case, the geomId must be provided separately by the meshInfo
    var geomId = undefined;
    if (meshInfo.geometry.hash) {
      // shared otg geom
      if (meshInfo.geomId === undefined) {
        console.error("meshInfo must provide geomId");
      }
      geomId = meshInfo.geomId;
    } else {
      // svf geom
      geomId = meshInfo.geometry.svfid;
    }

    this.geomids[fragId] = geomId;

    this.setMaterial(fragId, meshInfo.material);
  }

  // Don't override the visibility flag which could be set before geometry is ready.
  // This can improve the performance when streaming geometry and rendering happen together.
  var typeFlags = 0;
  if (meshInfo.isLine) typeFlags = MeshFlags.MESH_ISLINE;else
  if (meshInfo.isWideLine) typeFlags = MeshFlags.MESH_ISWIDELINE;else
  if (meshInfo.isPoint) typeFlags = MeshFlags.MESH_ISPOINT;
  if (!this.isFixedSize) {
    this.vizflags[fragId] |= MeshFlags.MESH_VISIBLE | typeFlags;
  } else
  {
    this.vizflags[fragId] |= typeFlags;
  }

  if (updateFragmentData) {
    // Update transform and bb
    var transform = meshInfo.matrix;

    // Copy the transform to the fraglist array
    // We store in column-major order like the elements of the Matrix4, but skip row 3.
    var i = fragId * 12;
    var cur = transform.elements;
    var orig = this.transforms;
    orig[i] = cur[0];
    orig[i + 1] = cur[1];
    orig[i + 2] = cur[2];
    orig[i + 3] = cur[4];
    orig[i + 4] = cur[5];
    orig[i + 5] = cur[6];
    orig[i + 6] = cur[8];
    orig[i + 7] = cur[9];
    orig[i + 8] = cur[10];
    orig[i + 9] = cur[12];
    orig[i + 10] = cur[13];
    orig[i + 11] = cur[14];

    // When using Otg, computed bboxes are only used until we get the actual ones from fragments_extra.
    // Once loaded, they must not be overwritten by computed ones (which are too large in some cases).
    if (!this.fragments.boxesLoaded) {
      // Transform the local BB to world
      if (meshInfo.geometry && meshInfo.geometry.boundingBox) {
        _tmpBox.copy(meshInfo.geometry.boundingBox);
      } else {
        this.geoms.getModelBox(this.geomids[fragId], _tmpBox);
      }

      if (!_tmpBox.empty()) {
        _tmpBox.applyMatrix4(transform);
      }

      // Write bounding box to this.boxes
      var boffset = fragId * 6;
      var bb = this.boxes;
      bb[boffset] = _tmpBox.min.x;
      bb[boffset + 1] = _tmpBox.min.y;
      bb[boffset + 2] = _tmpBox.min.z;
      bb[boffset + 3] = _tmpBox.max.x;
      bb[boffset + 4] = _tmpBox.max.y;
      bb[boffset + 5] = _tmpBox.max.z;
    }
  }
};


FragmentList.prototype.isFlagSet = function (fragId, flag) {
  return !!(this.vizflags[fragId] & flag);
};

/**
    * Set/unset flag of a fragment.
    * Note: Changing MESH_VISIBLE requires to update allVisibleDirty as well => Use setVisibility() for this case.
    * @param {number} fragId - Fragment ID.
    * @param {number} flag - Must be one of the flags defined at the beginning of this file, e.g., MESH_HIGHLIGHTED.
    * @returns {bool} False if nothing changed.
    */
FragmentList.prototype.setFlagFragment = function (fragId, flag, value) {

  // If flag is already defined and has this value, just return false.
  var old = this.vizflags[fragId];
  if (!!(old & flag) == value) // "!!" casts to boolean, "==" is intentional.
    return false;

  // set or unset flag
  if (value)
  this.vizflags[fragId] = old | flag;else

  this.vizflags[fragId] = old & ~flag;

  return true;
};

/**
    * Set/unset flag for all fragments, e.g. setFlagGlobal(MESH_VISIBLE, true);
    * Note: Changing MESH_VISIBLE requires to update allVisibleDirty as well => use setAllVisibility() for this case.
    * @param {number} flag - Must be one of the flags defined at the beginning of this file, e.g., MESH_HIGHLIGHTED.
    * @param {bool} value - Value to be set to the flag
    */
FragmentList.prototype.setFlagGlobal = function (flag, value) {
  var vizflags = this.vizflags;
  var i = 0,iEnd = vizflags.length;
  if (value) {
    for (; i < iEnd; i++) {
      vizflags[i] = vizflags[i] | flag;
    }
  } else {
    var notflag = ~flag;
    for (; i < iEnd; i++) {
      vizflags[i] = vizflags[i] & notflag;
    }
  }
};

/**
    * Marks all lines as visible or hidden.
    * Works like this.setFlagGlobal(MESH_HIDE, hide), but only affects fragments with MESH_ISLINE flag.
    * @param {bool} hide - Desired visibility status.
    */
FragmentList.prototype.hideLines = function (hide) {
  this.linesHidden = hide;
};

/**
    * Marks all points as visible or hidden.
    * Works like this.setFlagGlobal(MESH_HIDE, hide), but only affects fragments with MESH_ISPOINT flag.
    * @param {bool} hide - Desired visibility status.
    */
FragmentList.prototype.hidePoints = function (hide) {
  this.pointsHidden = hide;
};

/**
    * Marks all fragments with the given flag as visible or hidden.
    * Works like this.setFlagGlobal(MESH_HIDE, hide), but only affects fragments with given flag.
    * @param {number} typeFlag - visibility flag of fragments to change
    * @param {bool} hide - Desired visibility status.
    */
FragmentList.prototype.hideFragments = function (typeFlag, hide) {

  var flag = MeshFlags.MESH_HIDE;

  var vizflags = this.vizflags;
  var i = 0,iEnd = vizflags.length;
  if (hide) {
    for (; i < iEnd; i++) {
      if (vizflags[i] & typeFlag)
      vizflags[i] = vizflags[i] | flag;
    }
  } else {
    var notflag = ~flag;
    for (; i < iEnd; i++) {
      if (vizflags[i] & typeFlag)
      vizflags[i] = vizflags[i] & notflag;
    }
  }

  // Mark allVisible as outdated        
  this.allVisibleDirty = true;
};

/**
    * Checks visibility of a fragment.
    * @param {number} frag - Fragment ID.
    * @returns {bool} True if the fragment is visible and not highlighted nor hidden.
    */
FragmentList.prototype.isFragVisible = function (frag) {
  var isHiddenLine = this.linesHidden && (this.isLine(frag) || this.isWideLine(frag));
  var isHiddenPoint = this.pointsHidden && this.isPoint(frag);
  return (this.vizflags[frag] & 7 /*MESH_VISIBLE|MESH_HIGHLIGHTED|MESH_HIDE*/) == 1 && !isHiddenLine && !isHiddenPoint;
};

FragmentList.prototype.isFragOff = function (frag) {
  return !!(this.vizflags[frag] & MeshFlags.MESH_HIDE);
};

/*
    * Returns true if a fragment was excluded from loading or unloaded. 
    *
    * Note that isNotLoaded()==false does not guarantee that geometry and material are already in memory.
    * Only vice versa: If true, it will not be loaded at all.
    */
FragmentList.prototype.isNotLoaded = function (frag) {
  return this.vizflags[frag] & MeshFlags.MESH_NOTLOADED;
};

FragmentList.prototype.isLine = function (frag) {
  return !!(this.vizflags[frag] & MeshFlags.MESH_ISLINE /*MESH_VISIBLE|MESH_HIGHLIGHTED*/);
};

FragmentList.prototype.isWideLine = function (frag) {
  return this.isFlagSet(frag, MeshFlags.MESH_ISWIDELINE);
};

FragmentList.prototype.isPoint = function (frag) {
  return this.isFlagSet(frag, MeshFlags.MESH_ISPOINT);
};


// [HB:] This method does not consider the MESH_HIDE flag, but this.setFragOff seems to expect this, because it sets allVisibleDirty.
//       Is this a bug?
FragmentList.prototype.areAllVisible = function () {

  // update allVisible if any flags have changed
  if (this.allVisibleDirty) {

    // allVisible <=> MESH_VISIBLE is set for all fragments
    var vizflags = this.vizflags;
    var allVisible = true;
    for (var i = 0, iEnd = vizflags.length; i < iEnd; i++) {
      if ((vizflags[i] & 1 /*MESH_VISIBLE*/) === 0) {
        allVisible = false;
        break;
      }
    }

    this.allVisible = allVisible;
    this.allVisibleDirty = false;
  }

  return this.allVisible;
};

// Swaps r/b channels in a THREE.Color object.
function swapRBChannels(color) {
  var tmp = color.r;
  color.r = color.b;
  color.b = tmp;
  return color;
}

/** Linear interpolation between original color and theming color based on theming intensity.
   * @param origColor    {number}        original uint32 color from vertex-buffer. alpha is vertex-opacity
   * @param themingColor {THREE.Vector4} theming color as vec4f. Channels are (r,g,b,a) where alpha is theming intensity.
   * @returns finalColor {number}        final color as uint32
   */
var applyThemingColorAndVisibility = function () {
  var tmp1 = null;
  var tmp2 = null;
  var rgbMask = parseInt("00FFFFFF", 16);
  var alphaMask = parseInt("FF000000", 16);
  return function (origColor, themingColor) {
    if (!tmp1) {
      tmp1 = new THREE.Color();
      tmp2 = new THREE.Color();
    }

    tmp1.set(origColor & rgbMask);

    // THREE.Color denotes uint color in BGRA order (i.e., Blue in the lowest byte).
    // In the vertex-buffer, we use RGBA - so we have to swap when converting between these two.
    swapRBChannels(tmp1);

    if (themingColor) {
      // set tmp2 to theming color
      tmp2.setRGB(themingColor.x, themingColor.y, themingColor.z);

      // blend original color with theming color
      tmp1.lerp(tmp2, themingColor.w);
    }

    // convert back to color-buffer uint32 and preserve original alpha bits
    return swapRBChannels(tmp1).getHex() | origColor & alphaMask;
  };
}();

// Updates the per-vertex array of a mesh to reflect latest theming and ghosting state.
// Note that this can only work on F2D meshes with known attributes and interleaved vertex buffer.
function updateVertexBufferForThemingAndGhosting(fragList, fragId) {

  // get backup of original per-vertex colors (undef if color array is currently not modified)
  var origColors = fragList.originalColors[fragId];

  // check if anything changed
  if (!fragList.themingOrGhostingNeedsUpdate[fragId]) {
    return;
  }

  // get values to access colors and ids
  var geom = fragList.getGeometry(fragId);
  var attr = geom ? geom.attributes : null;
  var atColors = attr ? attr.color4b : null;
  var atIds = attr ? attr.dbId4b : null;
  var atLayerVp = attr ? attr.layerVp4b : null;
  var atFlags = attr ? attr.flags4b : null;

  if (!atColors || !atIds || !geom.vb || !atLayerVp || !atFlags) {
    // we cannot work on this mesh.
    return;
  }

  // get uint32 view on interleaved vertex buffer
  var vertexData = new Uint32Array(geom.vb.buffer);
  var stride = geom.vbstride; // elems per vertex
  var vertexCount = vertexData.length / geom.vbstride;

  // Track if any colors/layers are affected by theming/ghosting. If not, we can drop the color/layer array backup at the end.
  var themingApplied = false;

  // Constants used for ghosting of 2D objects
  var PaperLayer = 0; // we use the paper layer to determine the paper sheet background (see F2d.js initSheet). This shape must be excluded from ghosting.

  // update vertex-color for each vertex
  var colOffset = atColors.itemOffset;
  var idOffset = atIds.itemOffset;
  var layerOffset = atLayerVp.itemOffset;
  var flagsOffset = atFlags.itemOffset;
  for (var i = 0; i < vertexCount; i++) {

    // get vertex-id and original color
    var dbId = vertexData[i * stride + idOffset];
    var color = origColors ? origColors[i] : vertexData[i * stride + colOffset];
    var layerVp = vertexData[i * stride + layerOffset];

    // sign extend the upper byte to get back negative numbers (since per-vertex ids are clamped from 32 bit to 24 bit)
    dbId = dbId << 8 >> 8;

    var isPaper = dbId == -1 && (layerVp & parseInt("FFFF", 16)) == PaperLayer;

    // is this id affected by theming?
    var themeColor = fragList.db2ThemingColor[dbId];
    var isHidden = fragList.dbIdIsHidden[dbId];
    if (!themeColor && !isHidden) {
      // no theming for this vertex
      if (origColors) {
        // restore original color
        color = origColors[i];
      } // else: if there is no backup array, the vertex-color is already the original.
    } else {
      // this vertex-color will be affected by theming.
      // make sure that we have backup.
      if (!origColors) {
        // backup original colors before we modify them.
        origColors = new Uint32Array(vertexCount);
        for (var j = 0; j < vertexCount; j++) {
          origColors[j] = vertexData[j * stride + colOffset];
        }
        fragList.originalColors[fragId] = origColors;
      }

      // replace vertex-color based on theming and visibility
      if (isHidden) {
        color = 0;
      } else {
        color = applyThemingColorAndVisibility(color, themeColor);
      }

      // signal that the color backup array is still needed
      themingApplied = true;
    }

    // color -> vertexBuffer
    vertexData[i * stride + colOffset] = color;

    // is this id affected by theming?
    var isGhosted = fragList.dbIdIsGhosted[dbId] && !isPaper;
    var flags = vertexData[i * stride + flagsOffset];
    if (isGhosted)
    flags |= 0xff << 24;else

    flags &= ~(0xff << 24);

    // layer -> vertexBuffer
    vertexData[i * stride + flagsOffset] = flags;
  }

  // if theming is off for all vertices, drop the backup array
  if (!themingApplied) {
    fragList.originalColors[fragId] = null;
  }

  // trigger refresh of GPU-side vertex buffer
  geom.vbNeedsUpdate = true;

  // don't touch this mesh again until new theming changes are done
  fragList.themingOrGhostingNeedsUpdate[fragId] = false;
}

/**
   * Provides an actual mesh for specific fragment.
   * NOTE: For (this.useThreeMesh==false), the returned value is volatile and will be overwritten on next call!
   * @param {number} fragId - Fragment ID.
   * @returns {THREE.Mesh} Mesh for the given fragment.
   */
FragmentList.prototype.getVizmesh = function () {

  //A scratch object that we fill in and return in the case
  //we don't use THREE.Mesh for persistent storage. If the caller
  //needs to hold on to the mesh outside the callback scope, it has to clone it.

  var m = new THREE.Mesh(undefined, undefined, true);
  m.isTemp = true;
  m.dbId = 0;
  m.modelId = 0;
  m.fragId = -1;
  m.hide = false;
  m.isLine = false;
  m.isWideLine = false;
  m.isPoint = false;

  return function (fragId, renderImportance, putInProxy) {

    // make sure that vertex-colors reflect the latest theming-state
    if (this.is2d)
    updateVertexBufferForThemingAndGhosting(this, fragId);

    if (this.useThreeMesh) {
      return this.vizmeshes[fragId];
    } else {


      // init temp mesh object from geometry, material etc.
      m.geometry = this.getGeometry(fragId); // BufferGeometry
      m.material = this.getMaterial(fragId); // THREE.ShaderMaterial
      m.dbId = this.getDbIds(fragId);
      m.modelId = this.modelId;
      m.fragId = fragId;
      m.visible = true;
      m.isLine = this.isLine(fragId);
      m.isWideLine = this.isWideLine(fragId);
      m.isPoint = this.isPoint(fragId);
      m.hide = this.isFragOff(fragId);
      m.themingColor = this.db2ThemingColor[m.dbId];

      this.getWorldMatrix(fragId, m.matrixWorld);

      return m;
    }
  };

}();


FragmentList.prototype.getMaterialId = function (fragId) {
  var m = this.getMaterial(fragId);
  return m ? m.id : 0;
};

FragmentList.prototype.getMaterial = function (fragId) {
  // material ids are either stored with vizmeshes or in the material map.
  return this.useThreeMesh ? this.vizmeshes[fragId].material : this.materialIdMap[this.materialids[fragId]];
};

FragmentList.prototype.getGeometry = function (fragId) {
  // geometry is either stored in with vizmoeshes or obtained from this.geoms.
  // Make sure this.vizmesh[fragId] isn't null or undefined
  var mesh;
  return this.useThreeMesh ?
  (mesh = this.vizmeshes[fragId]) ? mesh.geometry : null :
  this.geoms.getGeometry(this.geomids[fragId]);
};

FragmentList.prototype.getGeometryId = function (fragId) {
  // When using THREE.Meshes, fragIds and geomids are the same and this.geomids is not used.
  return this.useThreeMesh ? fragId : this.geomids[fragId];
};

FragmentList.prototype.setMaterial = function (fragId, material) {

  if (this.useThreeMesh) {

    this.vizmeshes[fragId].material = material;

  } else {

    var matId = this.materialmap[material.id];

    if (!matId) {

      //Material.id is global, hence we can't expect it to be a small
      //integer. Hence the incrementing counter indirection.
      matId = this.nextMaterialId++;

      this.materialids = reallocateUintArrayMaybe(this.materialids, matId);

      this.materialIdMap[matId] = material;

      //remember our local ID for this global material
      this.materialmap[material.id] = matId;
    }

    this.materialids[fragId] = matId;
  }
};

FragmentList.prototype.getCount = function () {
  return this.vizmeshes ? this.vizmeshes.length : this.vizflags.length;
};

FragmentList.prototype.getDbIds = function (fragId) {
  return this.fragments.fragId2dbId[fragId];
};

// glRenderer: instanceof WebGLRenderer (only neeeded when for this.useThreeMesh==false)
FragmentList.prototype.dispose = function (glrenderer) {

  if (this.useThreeMesh) {

    // dispatch remove event to all meshes and dispose events to all BufferGeometry buffers
    // This will trigger EventListeners added by WebGLRenderer that deallocate the geometry later.
    // (see onGeometryDispose(..) in WebGLRenderer.js)
    var DISPOSE_EVENT = { type: 'dispose' };
    var REMOVED_EVENT = { type: 'removed' };
    for (var i = 0; i < this.vizmeshes.length; i++) {
      var m = this.vizmeshes[i];
      if (m) {
        m.dispatchEvent(REMOVED_EVENT);
        m.geometry.dispatchEvent(DISPOSE_EVENT);
      }
    }
  } else {
    // Delete all geometry data immediately (see WebGLRenderer.deallocateGeometry)
    this.geoms.dispose(glrenderer);
  }
};

// This function should probably not be called outside VisibityManager
// in order to maintain node visibility state.
FragmentList.prototype.setVisibility = function (fragId, value) {
  this.setFlagFragment(fragId, MeshFlags.MESH_VISIBLE, value);
  this.allVisibleDirty = true;
};


// Note that this function switches whole meshes on/off. It cannot be used to toggle visibility of
// single 2D objects within a single mesh. For this one, use setObject2DVisible instead.
FragmentList.prototype.setFragOff = function (fragId, value) {
  this.setFlagFragment(fragId, MeshFlags.MESH_HIDE, value);
  this.allVisibleDirty = true; // [HB:] Either this should be removed or this.areAllVisible should consider MESH_HIDE
};


FragmentList.prototype.setAllVisibility = function (value) {
  if (this.is2d) {
    var frags = this.fragments;
    if (frags && frags.dbId2fragId) {
      for (var id in frags.dbId2fragId) {
        this.setObject2DGhosted(parseInt(id), !value);
      }
    }
  } else {
    this.setFlagGlobal(MeshFlags.MESH_VISIBLE, value);

    this.allVisible = value;
    this.allVisibleDirty = false;
  }
};

/**
    * Updates animation transform of a specific fragment.
    * Note: 
    *      - If scale/rotation/translation are all null, the call resets the whole transform, i.e., no anim transform is assigned anymore.
    *      - Leaving some of them null means to leave them unchanged.
    * @param {number} fragId - Fragment ID.
    * @param {Vector3=} scale
    * @param {Quaternion=} rotationQ
    * @param {Vector3=} translation
    */
FragmentList.prototype.updateAnimTransform = function (fragId, scale, rotationQ, translation) {

  var ax = this.animxforms;
  var off;

  //Allocate animation transforms on first use.
  if (!ax) {
    var count = this.getCount();
    ax = this.animxforms = new Float32Array(10 * count); //3 scale + 4 rotation + 3 translation
    for (var i = 0; i < count; i++) {
      // get start index of the anim transform of fragment i
      off = i * 10;

      // init as identity transform
      ax[off] = 1; // scale.x
      ax[off + 1] = 1; // scale.y
      ax[off + 2] = 1; // scale.z
      ax[off + 3] = 0; // rot.x
      ax[off + 4] = 0; // rot.y
      ax[off + 5] = 0; // rot.z
      ax[off + 6] = 1; // rot.w
      ax[off + 7] = 0; // trans.x
      ax[off + 8] = 0; // trans.y
      ax[off + 9] = 0; // trans.z
    }
  }

  off = fragId * 10;
  var moved = false;

  if (scale) {
    ax[off] = scale.x;
    ax[off + 1] = scale.y;
    ax[off + 2] = scale.z;
    moved = true;
  }

  if (rotationQ) {
    ax[off + 3] = rotationQ.x;
    ax[off + 4] = rotationQ.y;
    ax[off + 5] = rotationQ.z;
    ax[off + 6] = rotationQ.w;
    moved = true;
  }

  if (translation) {
    ax[off + 7] = translation.x;
    ax[off + 8] = translation.y;
    ax[off + 9] = translation.z;
    moved = true;
  }

  // Set MESH_MOVED if an animation transform has been assigned. Just if scale/rotation/translation are all null, unset the flag.
  this.setFlagFragment(fragId, MeshFlags.MESH_MOVED, moved);

  //Assume that if we are called with null everything the caller wants to reset the transform.
  if (!moved) {
    // reset to identity transform
    ax[off] = 1;
    ax[off + 1] = 1;
    ax[off + 2] = 1;
    ax[off + 3] = 0;
    ax[off + 4] = 0;
    ax[off + 5] = 0;
    ax[off + 6] = 1;
    ax[off + 7] = 0;
    ax[off + 8] = 0;
    ax[off + 9] = 0;
  }
};

/**
    * Returns animation transform of a specific fragment.
    * @param {number} fragId - Fragment ID.
    * @param {THREE.Vector3=} scale - Output param.
    * @param {THREE.Quaternion=} rotationQ - Output param.
    * @param {THREE.Vector3=} translation - Output param.
    * @returns {bool} True if an anim transform is assigned to the given fragment.
    *      If so, it is written to the given out params. False otherwise (outparams not changed).
    */
FragmentList.prototype.getAnimTransform = function (fragId, scale, rotationQ, translation) {

  if (!this.animxforms)
  return false;

  if (!this.isFlagSet(fragId, MeshFlags.MESH_MOVED))
  return false;

  var off = fragId * 10;
  var ax = this.animxforms;

  if (scale) {
    scale.x = ax[off];
    scale.y = ax[off + 1];
    scale.z = ax[off + 2];
  }

  if (rotationQ) {
    rotationQ.x = ax[off + 3];
    rotationQ.y = ax[off + 4];
    rotationQ.z = ax[off + 5];
    rotationQ.w = ax[off + 6];
  }

  if (translation) {
    translation.x = ax[off + 7];
    translation.y = ax[off + 8];
    translation.z = ax[off + 9];
  }

  return true;
};

/**
    * Returns world matrix of a fragment.
    * @param {number} index - Fragment ID.
    * @param {THREE.Matrix4} dstMtx - Out param to receive the matrix.
    */
FragmentList.prototype.getOriginalWorldMatrix = function (index, dstMtx) {
  var i = index * 12;

  var cur = dstMtx.elements;
  var orig = this.transforms;

  if (orig) {
    // If this.transforms is defined, copy transform from this array            

    // In this.transforms, we only store the upper 3 rows explicitly. 
    // The last row is alway (0,0,0,1).
    cur[0] = orig[i];
    cur[1] = orig[i + 1];
    cur[2] = orig[i + 2];
    cur[3] = 0;
    cur[4] = orig[i + 3];
    cur[5] = orig[i + 4];
    cur[6] = orig[i + 5];
    cur[7] = 0;
    cur[8] = orig[i + 6];
    cur[9] = orig[i + 7];
    cur[10] = orig[i + 8];
    cur[11] = 0;
    cur[12] = orig[i + 9];
    cur[13] = orig[i + 10];
    cur[14] = orig[i + 11];
    cur[15] = 1;
  } else if (this.useThreeMesh) {
    // get matrix directly from THREE.Mesh
    var m = this.getVizmesh(index);
    if (m)
    dstMtx.copy(m.matrixWorld);else

    dstMtx.identity();
  } else {
    dstMtx.identity();
  }
};


/**
    * Writes the final world matrix of a fragment to out param dstMtx.
    * The world matrix results from original transform and anim transform (if any).
    * @param {number} index - Fragment ID.
    * @param {THREE.Matrix4} dstMtx - Out param to receive the matrix.
    */
FragmentList.prototype.getWorldMatrix = function () {

  //Allocate a second temp variable here because the input can be the class scoped
  //temporary matrix in some call sequences.
  var tmp = new THREE.Matrix4();

  return function (index, dstMtx) {

    this.getOriginalWorldMatrix(index, dstMtx);

    //If mesh hasn't moved from its original location, just use that.
    if (!this.isFlagSet(index, MeshFlags.MESH_MOVED)) {
      return;
    }

    //Otherwise construct the overall world matrix
    this.getAnimTransform(index, _tmpScale, _tmpRot, _tmpPos);

    // compose matrix from pos, rotation, and scale
    tmp.compose(_tmpPos, _tmpRot, _tmpScale);

    // First apply original matrix (in dstMtx), then anim matrix (in tmp).
    // Note that tmp muist be multipled from left for this.
    dstMtx.multiplyMatrices(tmp, dstMtx);
  };
}();

/**
      * Writes the world box to dstBox outparams, considering matrix and anim transform (if specified).
      * @param {number} index - Fragment ID.
      * @param {THREE.Box3|LmvBox3} dstBox - result is saved here
      */
FragmentList.prototype.getWorldBounds = function (index, dstBox) {

  //Check if the world transform of the mesh is unchanged from
  //the original LMV file -- in such case we can use the original
  //bounding box from the LMV package, which is presumably more precise (tighter)
  //than just transforming the model box.
  //This is important if we want to keep our bounding volume hierarchy efficient.
  if (this.boxes && !this.isFlagSet(index, MeshFlags.MESH_MOVED)) {
    var b = this.boxes;
    var boffset = index * 6;
    dstBox.min.x = b[boffset];
    dstBox.min.y = b[boffset + 1];
    dstBox.min.z = b[boffset + 2];
    dstBox.max.x = b[boffset + 3];
    dstBox.max.y = b[boffset + 4];
    dstBox.max.z = b[boffset + 5];
    return;
  }

  // get original model box
  if (this.useThreeMesh) {
    // either from THREE.Mesh
    var m = this.getVizmesh(index);
    if (m && m.geometry) {
      dstBox.copy(m.geometry.boundingBox);
    }
  } else
  {
    // or from GeometryList
    this.geoms.getModelBox(this.geomids[index], dstBox);
  }

  if (!dstBox.empty()) {
    // apply world matrix to dstBox
    this.getWorldMatrix(index, _tmpMatrix);
    dstBox.applyMatrix4(_tmpMatrix);
  }
};


/**
    * Writes the original (as loaded) world box to dstBox outparams. Does not take into account changes
    * to object position like explode/animation.
    * @param {number} index - Fragment ID.
    * @param {Array} dstBox - Array where result is stored as 6 consecutive numbers
    */
FragmentList.prototype.getOriginalWorldBounds = function (index, dstBox) {

  if (this.boxes) {
    var b = this.boxes;
    var boffset = index * 6;
    dstBox[0] = b[boffset];
    dstBox[1] = b[boffset + 1];
    dstBox[2] = b[boffset + 2];
    dstBox[3] = b[boffset + 3];
    dstBox[4] = b[boffset + 4];
    dstBox[5] = b[boffset + 5];
    return;
  }

  // get original model box
  if (this.useThreeMesh) {
    // either from THREE.Mesh
    var m = this.getVizmesh(index);
    if (m && m.geometry) {
      _tmpBox.copy(m.geometry.boundingBox);
    }
  } else
  {
    // or from GeometryList
    this.geoms.getModelBox(this.geomids[index], _tmpBox);
  }

  if (!_tmpBox.empty()) {
    // apply world matrix to dstBox
    this.getOriginalWorldMatrix(index, _tmpMatrix);
    _tmpBox.applyMatrix4(_tmpMatrix);
  }

  dstBox[0] = _tmpBox.min.x;
  dstBox[1] = _tmpBox.min.y;
  dstBox[2] = _tmpBox.min.z;
  dstBox[3] = _tmpBox.max.x;
  dstBox[4] = _tmpBox.max.y;
  dstBox[5] = _tmpBox.max.z;
};


// set themingNeedsUpdate flag for all vizmeshes that contain a given dbId
function setThemingOrGhostingNeedsUpdateFlag(fragList, dbId) {

  if (!fragList.is2d) {
    // In this case (3D model), we just have theming colors per mesh and don't need to update vertex buffers.
    return;
  }

  // get id(s) of affected mesh(es) that needs a vertex-color update
  var fragIds = fragList.fragments.dbId2fragId[dbId];

  //  trigger update for single id or an array of ids
  if (Array.isArray(fragIds)) {
    for (var i = 0; i < fragIds.length; i++) {
      fragList.themingOrGhostingNeedsUpdate[fragIds[i]] = true;
    }
  } else if (typeof fragIds === 'number') {
    fragList.themingOrGhostingNeedsUpdate[fragIds] = true;
  }
}

/**
   * Applies a theming color that is blended with the final fragment color of a material shader.
   * @param {number}        dbId
   * @param {THREE.Vector4} [color] - theming color (in xyz) and intensity (in w). All components in [0,1].
   *                                  Set to undefined for 'no theming'
   */
FragmentList.prototype.setThemingColor = function (dbId, color) {
  // Stop if color keeps the same
  var oldColor = this.db2ThemingColor[dbId];
  var colorsEqual = oldColor === color || oldColor && color && oldColor.equals(color);
  if (!colorsEqual) {
    this.db2ThemingColor[dbId] = color;
    setThemingOrGhostingNeedsUpdateFlag(this, dbId);
  }
};

/** Restore original colors for all themed shapes. */
FragmentList.prototype.clearThemingColors = function () {

  // When using F2D (model.is2d()==true), we have to update the restore the original
  // per-vertex colors. For 3D, we can use per-shape colors, so that this step is not
  // needed.
  if (this.is2d) {
    // trigger update for all meshes that were affected by theming before
    // Note that dbId2fragId only exists for F2D models.
    for (var id in this.fragments.dbId2fragId) {
      setThemingOrGhostingNeedsUpdateFlag(this, parseInt(id));
    }
  }

  // clear theming-color map
  this.db2ThemingColor.length = 0;
};

/** Set ghosting flag for a 2D object. This reduces the objects opacity, blends it with pageColor, and excludes it from selection.
    *  @param {number} dbId
    *  @param {bool}   state
    */
FragmentList.prototype.setObject2DGhosted = function (dbId, state) {
  var oldState = this.dbIdIsGhosted[dbId];
  if (!!state !== !!oldState) {
    this.dbIdIsGhosted[dbId] = state;
    setThemingOrGhostingNeedsUpdateFlag(this, dbId);
  }
};

/** Set hide flag for a 2D object. This sets opacity to 0.0, which also excludes it from selection.
    *  @param {number} dbId
    *  @param {bool}   visible
    */
FragmentList.prototype.setObject2DVisible = function (dbId, visible) {
  var wasVisible = !this.dbIdIsHidden[dbId];
  if (visible !== wasVisible) {
    this.dbIdIsHidden[dbId] = !visible;
    setThemingOrGhostingNeedsUpdateFlag(this, dbId);
  }
};

/**
    * Convenience class encapsulating a single fragment in a given FragmentList.
    * Use sparingly, as it is expensive to have those for every fragment in memory.
    * 
    * @see Autodesk.Viewing.Viewer3D#getFragmentPointer
    * @see Autodesk.Viewing.Viewer3D#getModel
    * 
    * @example
    * var avp = Autodesk.Viewing.Private;
    * var fragPointer = new avp.FragmentPointer(viewer.model.getFragmentList(), 4); // Get the fragment proxy for some frag id
    * // The Model class also exposes the following method to get the FragmentPointer:
    * // var fragPointer = viewer.model.getFragmentPointer(4); 
    * 
    * @constructor
    * 
    * @param {Autodesk.Viewing.Private.FragmentList} frags - the fragment list
    * @param {number} fragId - the fragment id
    * 
    * @alias Autodesk.Viewing.Private.FragmentPointer
    */
export function FragmentPointer(frags, fragId) {

  this.frags = frags; // fragment list
  this.fragId = fragId; // id of a fragment in frags

  // used by MeshAnimation
  this.scale = null;
  this.quaternion = null;
  this.position = null;
}

/**
   * Writes the final world matrix of a fragment to dst.
   * The world matrix results from original transform and anim transform (if any).
   * @param {THREE.Matrix4} dst - Out param to receive the matrix.
   * @example
   * var matrix = new THREE.Matrix4() // Create an empty Matrix4
   * fragPointer.getWorldMatrix(matrix); // Set the new values to the matrix variable
   */
FragmentPointer.prototype.getWorldMatrix = function (dst) {
  this.frags.getWorldMatrix(this.fragId, dst);
};

/**
    * Writes the original world matrix of a fragment to dst.
    * @param {THREE.Matrix4} dst - Out param to receive the matrix.
    * @example
    * var matrix = new THREE.Matrix4() // Create an empty Matrix4
    * fragPointer.getOriginalWorldMatrix(matrix); // Set the new values to the matrix variable
    */
FragmentPointer.prototype.getOriginalWorldMatrix = function (dst) {
  this.frags.getOriginalWorldMatrix(this.fragId, dst);
};

/**
    * Writes the world box to dst param, considering matrix and anim transform (if specified).
    * @param {THREE.Box3|LmvBox3} dst - result is saved here
    * @example
    * var box = new THREE.Box3(); // Create an empty Box3
    * fragPointer.getWorldBounds(box); // Set the new values to the box variable
    * 
    */
FragmentPointer.prototype.getWorldBounds = function (dst) {
  return this.frags.getWorldBounds(this.fragId, dst);

};

/**
    * Sets the scale, quaternion and position to the animation transform of the the fragment.
    * @returns {boolean} True if an animation transform is set. Otherwise, it returns false and transform is set to identity.
    */
FragmentPointer.prototype.getAnimTransform = function () {

  if (!this.scale) {
    this.scale = new THREE.Vector3(1, 1, 1);
    this.quaternion = new THREE.Quaternion(0, 0, 0, 1);
    this.position = new THREE.Vector3(0, 0, 0);
  }

  return this.frags.getAnimTransform(this.fragId, this.scale, this.quaternion, this.position);

};

/**
    * Applies current scale/quaternion/position to the fragment.
    */
FragmentPointer.prototype.updateAnimTransform = function () {

  if (!this.scale) {
    this.scale = new THREE.Vector3(1, 1, 1);
    this.quaternion = new THREE.Quaternion(0, 0, 0, 1);
    this.position = new THREE.Vector3(0, 0, 0);
  }

  this.frags.updateAnimTransform(this.fragId, this.scale, this.quaternion, this.position);
};

/**
    * Returns the material associated with the fragment
    * @returns {THREE.Material} - Material
    */
FragmentPointer.prototype.getMaterial = function () {
  return this.frags.getMaterial(this.fragId);

};

/**
    * Set a material to the current fragment
    * @example
    * var material = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Create a new material
    * fragPointer.setMaterial(material); // Assign the new material to the fragment
    */
FragmentPointer.prototype.setMaterial = function (material) {

  return this.frags.setMaterial(this.fragId, material);

};