

/**
 * Fired after the toolbar UI gets instantiated.
 * @event Autodesk.Viewing#TOOLBAR_CREATED_EVENT
 */
export var TOOLBAR_CREATED_EVENT = 'toolbarCreated';

/**
                                                      * Fired after the ViewCube gets instantiated.
                                                      * @event Autodesk.Viewing#VIEW_CUBE_CREATED_EVENT
                                                      */
export var VIEW_CUBE_CREATED_EVENT = 'viewCubeCreated';

/**
                                                         * Viewer tools sets.
                                                         *
                                                         * These constants are used to define the standard set of tools.
                                                         *
                                                         * @enum {string}
                                                         * @readonly
                                                         * @memberof Autodesk.Viewing
                                                         */
export var TOOLBAR = {
  NAVTOOLSID: "navTools",
  MODELTOOLSID: "modelTools",
  SETTINGSTOOLSID: "settingsTools",
  MEASURETOOLSID: "measureTools" };