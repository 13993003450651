
import { Extension } from "../../src/application/Extension";
import { ViewerLayersPanel } from "../../src/gui/ViewerLayersPanel";


/**
                                                                      * Use its `activate()` method to open the LayersPanel UI.
                                                                      * Layers are usually present in 2D models, but some 3D models may support
                                                                      * layers as well, for example: AutoCAD.
                                                                      * 
                                                                      * The extension id is: `Autodesk.LayerManager`
                                                                      * 
                                                                      * @example
                                                                      *   viewer.loadExtension('Autodesk.LayerManager')
                                                                      * 
                                                                      * @memberof Autodesk.Viewing.Extensions
                                                                      * @alias Autodesk.Viewing.Extensions.LayerManagerExtension
                                                                      * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
                                                                      * @constructor
                                                                      */
export function LayerManagerExtension(viewer, options) {
  Extension.call(this, viewer, options);
  this.viewer = viewer;
  this.options = options;
  this.name = "layermanager";
}
LayerManagerExtension.prototype = Object.create(Extension.prototype);
LayerManagerExtension.prototype.constructor = LayerManagerExtension;

var proto = LayerManagerExtension.prototype;

/**
                                              * Opens the Layers Panel UI.
                                              * 
                                              * @memberof Autodesk.Viewing.Extensions.LayerManagerExtension
                                              * @alias Autodesk.Viewing.Extensions.LayerManagerExtension#activate
                                              */
proto.activate = function () {
  if (!this.activeStatus) {
    if (!this.viewer.layersPanel) {
      this.viewer.setLayersPanel(new ViewerLayersPanel(this.viewer));
    }
    this.viewer.showLayerManager(true);
    this.activeStatus = true;
  }
  return true;
};

/**
    * Closes the Layers Panel UI.
    * 
    * @memberof Autodesk.Viewing.Extensions.LayerManagerExtension
    * @alias Autodesk.Viewing.Extensions.LayerManagerExtension#deactivate
    */
proto.deactivate = function () {
  if (this.activeStatus) {
    if (!this.viewer.layersPanel) {
      this.viewer.setLayersPanel(new ViewerLayersPanel(this.viewer));
    }
    this.viewer.showLayerManager(false);
    this.activeStatus = false;
  }
  return true;
};