
import { SnapType } from "./SnapTypes";

export function SnapResult() {
  this.clear();
}

SnapResult.prototype.clear = function () {

  this.geomType = null; // int, such as { "NONE: -1", "VERTEX": 0, "EDGE": 1, "FACE": 2, ... }
  this.snapNode = null; // int, the dbId
  this.geomVertex = null; // THREE.Vector3
  this.geomEdge = null; // THREE.Geometry
  this.geomFace = null; // THREE.Geometry
  this.radius = null; // Number
  this.intersectPoint = null; // THREE.Vector3
  this.faceNormal = null; // THREE.Vector3
  this.viewportIndex2d = null; // int
  this.circularArcCenter = null;
  this.circularArcRadius = null;
  this.fromTopology = false;
  this.isPerpendicular = false;
};

SnapResult.prototype.copyTo = function (destiny) {
  // Shallow copies of THREE objects should be fine.
  destiny.snapNode = this.snapNode;
  destiny.geomVertex = this.geomVertex;
  destiny.geomFace = this.geomFace;
  destiny.geomEdge = this.geomEdge;
  destiny.radius = this.radius;
  destiny.geomType = this.geomType;
  destiny.intersectPoint = this.intersectPoint;
  destiny.faceNormal = this.faceNormal;
  destiny.viewportIndex2d = this.viewportIndex2d;
  destiny.circularArcCenter = this.circularArcCenter;
  destiny.circularArcRadius = this.circularArcRadius;
  destiny.fromTopology = this.fromTopology;
  destiny.isPerpendicular = this.isPerpendicular;
};

SnapResult.prototype.clone = function () {
  var theClone = new SnapResult();
  this.copyTo(theClone);
  return theClone;
};

SnapResult.prototype.isEmpty = function () {
  return !this.getGeometry();
};

SnapResult.prototype.getFace = function () {
  return this.geomFace;
};

SnapResult.prototype.getEdge = function () {
  return this.geomEdge;
};

SnapResult.prototype.getVertex = function () {
  return this.geomVertex;
};

SnapResult.prototype.getGeometry = function () {

  switch (this.geomType) {
    case SnapType.SNAP_VERTEX:return this.geomVertex;
    case SnapType.SNAP_MIDPOINT:return this.geomVertex;
    case SnapType.SNAP_CIRCLE_CENTER:return this.geomVertex;
    case SnapType.SNAP_EDGE:return this.geomEdge;
    case SnapType.SNAP_FACE:return this.geomFace;
    case SnapType.SNAP_CIRCULARARC:return this.geomEdge;
    case SnapType.SNAP_CURVEDEDGE:return this.geomEdge;
    case SnapType.SNAP_CURVEDFACE:return this.geomFace;
    default:break;}

  return null;
};

SnapResult.prototype.setGeometry = function (type, geometry) {

  switch (type) {
    case SnapType.SNAP_VERTEX:this.geomVertex = geometry;break;
    case SnapType.SNAP_MIDPOINT:this.geomVertex = geometry;break;
    case SnapType.SNAP_CIRCLE_CENTER:this.geomVertex = geometry;break;
    case SnapType.SNAP_EDGE:this.geomEdge = geometry;break;
    case SnapType.SNAP_FACE:this.geomFace = geometry;break;
    case SnapType.SNAP_CIRCULARARC:this.geomEdge = geometry;break;
    case SnapType.SNAP_CURVEDEDGE:this.geomEdge = geometry;break;
    case SnapType.SNAP_CURVEDFACE:this.geomFace = geometry;break;
    default:return;}

  this.geomType = type;
};